import React from 'react'

function ConfirmationWindow({message, confirm, cancel}) {

  console.log("in ConfirmationWindow")

  return (
    <div className='window confirmationWindow'>
        <div className='closeButton' onClick={cancel}>x</div>
        <div>{message}</div>
        <div className='bottomButtons'>
            <button onClick={cancel} className='buttonHalf'>Cancel</button>
            <button onClick={confirm} className='buttonHalf'>Confirm</button>
        </div>
    </div>
  )
}
ConfirmationWindow.defaultProps = {
    message: "please add a message to the confirmation box", 
    confirm: ()=>{console.log("Please add a confirm function to the confirmation box")},
    cancel: ()=>{console.log("Please add a cancel function to the confirmation box")},
}
export default ConfirmationWindow