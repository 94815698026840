import React from 'react'

function EmojisWindow({insertEmoji, close}) {
    let emojis = [
        {
            emoji: "😀",
            name: "Happy",
          },
          {
            emoji: "😄",
            name: "Happy 2",
          },
          {
            emoji: "😅",
            name: "Sweat Happy",
          },
          {
            emoji: "🤣",
            name: "LOL",
          },
          {
            emoji: "🙂",
            name: "Smile",
          },
          {
            emoji: "🙃",
            name: "Smile Upsidedown",
          },
          {
            emoji: "😁",
            name: "Big Smile ",
          },
          {
            emoji: "😊",
            name: "Blush",
          },
          {
            emoji: "😝",
            name: "Tongue Out",
          },
            {
            emoji: "😋",
            name: "Tongue Out 2",
          },
          {
            emoji: "🥳",
            name: "Party",
          },
          {
            emoji: "😏",
            name: "Smirk",
          },
          {
            emoji: "😔",
            name: "Sorry",
          },
          {
            emoji: "😕",
            name: "Partial Frown",
          },
          {
            emoji: "🙁",
            name: "Frown",
          },
          {
            emoji: "😖",
            name: "Upset",
          },
          {
            emoji: "🥺",
            name: "Sad Eyes",
          },
          {
            emoji: "😢",
            name: "Tesr",
          },
          {
            emoji: "😭",
            name: "Crying",
          },
          {
            emoji: "😫",
            name: "Oh No",
          },
          {
            emoji: "😤",
            name: "Angry",
          },
          {
            emoji: "😐",
            name: "Blank Face",
          },
          {
            emoji: "😬",
            name: "Cringe",
          },
          {
            emoji: "🙄",
            name: "Ohh",
          },
          {
            emoji: "✋",
            name: "Wave",
          },
          {
            emoji: "🖖",
            name: "Live Long And Prosper",
          },
          {
            emoji: "👍",
            name: "Thumbs Up",
          },
          {
            emoji: "👎",
            name: "Thumbs Down",
          },
          {
            emoji: "❤️",
            name: "Heart",
          },
          {
            emoji: "💙",
            name: "Heart Blue",
          },       
    ]

  return (
    <div className='emojiWindow'>
        <div className='closeButton' onClick={close}>x</div>
        {emojis.map(emojiObject => (
            <div className='emojiSelector' onClick={()=>insertEmoji(emojiObject.emoji)}>
                {emojiObject.emoji}
            </div>
        ))}
    </div>
  )
}
EmojisWindow.defaultProps = {
    insertEmoji: (emoji) => {console.log("selected " + emoji);}
}
export default EmojisWindow