import React, { useEffect, useRef, useState } from 'react'
// import ChatBarChat from './ChatBarChat'
import editIcon from "../../Media/editIconBlue.png"
import minimizeIcon from "../../Media/minimizeIconBlue.png"
import { useDispatch, useSelector } from 'react-redux'
import { sortChatsByDate } from '../../Functions'
import { setShowChat, setShowNewChatSelector } from '../../GlobalState/AppSlice'
import ChatBar from './ChatBar'

/*
  showBottomButton is related to desktop panel view

  createNewChatFunction is not being sent into the component, but is needed

*/
function ChatBars({showBottomButton}) {

  const chats = useSelector(state => state.appSlice.chats)

  const dispatcher = useDispatch()

  const [filteredChats, setFilteredChats] = useState([])

  useEffect(()=>{
    filterChats()
  },[chats])

  function filterChats(){
    if(typeof chats !== "object"){
      setFilteredChats([])
      return
    }

    let dateSortedChats = sortChatsByDate(chats)

    setFilteredChats(dateSortedChats)

  }

  // Thi is for deskotp view and is not currently in use
  function openChatsWidow(){
    
  }

  return (
    <>
      <div className={'chatBar '+ (showBottomButton ? "chatBarWithBottomButton":"")}>
        <div className='chatBarTitle'>
          Chats
          <div className="imageButtonPosition2 imageButtonSize" title="Create New Chat" onClick={()=>dispatcher(setShowNewChatSelector(true))}>
            <div className='imageButtonImage2'>
              <img src={editIcon}></img>
            </div>
            <div className='imageButtonLabel'>New Chat</div>
          </div>
          <div className="imageButtonPosition imageButtonSize" title="Create New Chat" onClick={()=>dispatcher(setShowChat(false))}>
            <div className='imageButtonImage2'>
              <img src={minimizeIcon}></img>
            </div>
            <div className='imageButtonLabel'>Close</div>
          </div>
          {/* <div className='chatBarSearch'>
            <div className='searchImage'>
              <img src={menuIcon}></img>
            </div>
            <input placeholder='Search Chats' ref={filterInput} onChange={filterChats}></input>
          </div> */}
        </div>
        <div className={'chatBarsArea '+ (showBottomButton ? " chatBarsAreaShorter":"")}>
          {/* {additionalChatUserID && <ChatBarChat chatOtherUserID={additionalChatUserID} setChatOtherUserID={setChatOtherUserID} selectedChatUserID={selectedChatUserID}></ChatBarChat>} */}
          {filteredChats.map((chatData) => (
              <ChatBar chatDataParam={chatData} key={"chatBar"+chatData.id}></ChatBar>
          ))}       
        </div>
        {showBottomButton && 
          <div className='chatBarBottomButton' onClick={openChatsWidow}>
              See All In Window
          </div>
        }
      </div>
    </>
  )
}

export default ChatBars