import React, { useState } from 'react'
import "./Screening.css"
import logo from "../../Media/freeFerLogoWordsSide2.png"
// import logo from "../../Media/cryos.jpg"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';



function Screening() {

  const screeningData = {
    "Institution": "Cryos International - USA, LLC",
    "Institution": "Freedom Fertility, LLC",
    // "Address": "2200 N. Alafaya Trail | 32826 Orlando | USA",
    "Contact": {
      // "Website": "usa.cryosinternational.com",
      "Website": "FreedomFertility.org",
      "Email": "usa@cryosinternational.com",
      "Email": "Contact@FreedomFertility.com",
      // "Phone": "+1 (407) 203-1175"
    },
    "Date": dateString(16),
    "SummaryOfRecords": {
      "Type": "IDRelease",
      "Race": "Caucasian",
      "Ethnicity": "Irish, Italian",
      "EyeColor": "Blue",
      "HairColor": "Brown",
      "Height": "5-10",
      "Weight": "134 lbs",
      "PsychologicalProfile": "Qualified",
      "Education": "Bachelor's Degree",
      "Occupation": "Software Engineer",
      "BloodGroup": "O+",
      "ExtendedProfile": "Yes",
    },
    "InfectiousDiseases": {
      "HIV1_2": "Negative",
      "Chlamydia": "Negative",
      "Syphilis": "Negative",
      "Anti-HBc": "Negative",
      "Anti-HCV": "Negative",
      "HIV1_2-NAT": "Negative",
      "HCV-NAT": "Negative",
      "HBsAg": "Negative",
      "Gonorrhea": "Negative",
      "CMV": "Negative",
      "HTLV I/II": "Negative",
      "WNV": "Negative",
      "HBV-NAT": "Negative",
      "Zika Virus IgM": "Negative"
    },
    "GeneticCarrierTesting": {
      "Karyotype": "Normal",
      "Cystic Fibrosis (CFTR)": "Negative",
      "Familial Mediterranean Fever (MEFV)": "Negative",
      "Congenital Adrenal Hyperplasia (CYP21A2)": "Negative",
      "Alpha-thalassemi (HBA)": "Negative",
      "Canavan's Disease (ASPA)": "Negative",
      "Spinal Muscular Atrophy (SMN1)": "Negative",
      "Mucolipidosis Type IV (MCOLN1)": "Negative",
      "Bloom Syndrome (BLM)": "Negative",
      "Tay-Sachs Disease (HEXA)": "Negative",
      "Sickle Cell Disease (HBB)": "Negative",
      "Gaucher's Disease (GBA)": "Negative",
      "Beta-thalassemia (HBB)": "Negative",
      "Familial dysautonomia (IKBKAP)": "Negative",
      "Cystinosis (CTNS)": "Negative",
      "Fanconi Anemia Type C (FANCC)": "Negative",
      "Niemann-Pick Type A (SMPD1)": "Negative",
      "MTHFR Deficiency (MTHFR)": "Negative",
    },
    "officeUseOnly": {
      "First": "Abraham",
      "Last": "Apell",
      "Active": "True",
      "Ref#": "1842ISTR",
    }
  }

  // This one attempts to scale the image so its not blurry but it doens't work correctly
  function downloadScreening4() {
    const input = document.getElementById("screeningPage");
  
    const scale = 2; // Increase scale for higher resolution
    htmlToImage.toPng(input, { 
        quality: 1, 
        backgroundColor: 'white',
        width: input.clientWidth * scale,
        height: input.clientHeight * scale,
        style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left',
            width: input.clientWidth + 'px',
            height: input.clientHeight + 'px'
        }
    })
    .then(function (dataUrl) {
        const pdfWidth = 210;  // A4 width in mm
        const pdfHeight = 297; // A4 height in mm
        const imgWidth = input.clientWidth;
        const imgHeight = input.clientHeight;
        const ratio = (pdfWidth / imgWidth) * scale;
        const scaledHeight = imgHeight * ratio;
  
        const pdf = new jsPDF({
            orientation: 'portrait',
            // orientation: scaledHeight > pdfHeight ? 'landscape' : 'portrait',
            unit: 'mm',
            format: [pdfWidth, scaledHeight > pdfHeight ? scaledHeight : pdfHeight]
        });
  
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
  
        pdf.addImage(dataUrl, 'PNG', 0, 0, pageWidth, pageHeight);
        pdf.save("Screening " + screeningData.Date + ".pdf");
    });
  }

  // This one fits the output the the page
  function downloadScreening3() {
    const input = document.getElementById("screeningPage");
  
    htmlToImage.toPng(input, { quality: 1, backgroundColor: 'white' })
      .then(function (dataUrl) {
        const pdfWidth = 210;  // A4 width in mm
        const pdfHeight = 297; // A4 height in mm
        const imgWidth = input.clientWidth;
        const imgHeight = input.clientHeight;
        const ratio = pdfWidth / imgWidth;
        const scaledHeight = imgHeight * ratio;
  
        const pdf = new jsPDF({
          orientation: scaledHeight > pdfHeight ? "landscape" : "portrait",
          unit: "mm",
          format: [pdfWidth, scaledHeight > pdfHeight ? scaledHeight : pdfHeight]
        });
  
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
  
        pdf.addImage(dataUrl, 'PNG', 0, 0, width, height);
        pdf.save("Screening " + screeningData.Date + ".pdf");
      });
  }

  function downloadScreening2() {
    htmlToImage.toPng(document.getElementById("screeningPage"))
    .then(function (dataUrl) {
      // console.log("dataUrl")
      // console.log(dataUrl)
      // setDocUrl(dataUrl)
      const pdf = new jsPDF();
      pdf.addImage(dataUrl, 'PDF', 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("Screening "+screeningData.Date+".pdf");

      // saveAs(dataUrl, 'my-node.png');
    });
  }

  
  function downloadScreening() {
    const input = document.getElementById('screeningPage');
    // npm install html2canvas
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/pdf');
        // npm install jspdf
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PDF', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("Screening "+screeningData.Date+".pdf");
      })
    ;
  }

  function dateString(daysAgo){
    var date = new Date();
    date.setDate(date.getDate() - (daysAgo || 0));

    var day = date.getDate();
    var month = date.toLocaleString('default', { month: 'long' });
    var year = date.getFullYear();

    return day + " " + month + " " + year;
    }

  return (
    <div className='screeningPageOuter'>
      <button onClick={downloadScreening2}>Download</button>
      {/* <img src={docUrl}></img> */}
      <div className='screeningPage' id='screeningPage'>

        {/* Top Bar */}
        
        <div className='flexRowBetween pad'>
          <div className='left'>
            <div className='block blueText'>{screeningData.Institution}</div>
            {/* <div className='block'>{screeningData.Address}</div> */}
            <div className='block'>{"Website: "+screeningData.Contact.Website}</div>
            {/* <div className='block'>{"Email: "+screeningData.Contact.Email+" | Tel: "+screeningData.Contact.Phone}</div> */}
            <div className='block'>{"Email: "+screeningData.Contact.Email}</div>
          </div>
          <div className='screeningLogo'>
            <img src= {logo}></img>
          </div>
        </div>

        {/* Table: Donor Information */}

        <div className='screeningTables'>
          <div className='pad'>
            
            {/* Page Title */}

            <div className='flexRowBetween'>
              <div className='screeningTitle bold'>Summary Of Records</div>
              <div className='bold date'>{screeningData.Date}</div>
            </div>
            
            {/* Table Title */}
            
            <div className='blueBar'>
              Donor Information
            </div>

            {/* Table */}
            
            <div className='table'>
              {Object.entries(screeningData.SummaryOfRecords).map(([label, item], index) => (
                <div className={'flexRowBetween screeninTableRow '+(((index %4 == 2) || (index %4 == 3)) ? "grey":"")}>
                  <div className='rowHalf'>{label+":"}</div>
                  <div className='rowHalf bold'>{item}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table: InfectiousDiseases */}

          <div className='pad'>
            
            {/* Table Title */}

            <div className='blueBar'>
              Infectious Diseases
            </div>

            {/* Table */}
            
            <div className='table'>
              {Object.entries(screeningData.InfectiousDiseases).map(([label, item], index) => (
                <div className={'flexRowBetween screeninTableRow '+(((index %4 == 2) || (index %4 == 3)) ? "grey":"")}>
                  <div className='rowHalf'>{label}</div>
                  <div className='rowHalf bold'>{item}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table: GeneticCarrierTesting */}

          <div className='pad'>
            
            {/* Table Title */}

            <div className='blueBar'>
              Genetic Carrier Testing
            </div>

            {/* Table */}
            
            <div className='table'>
              {Object.entries(screeningData.GeneticCarrierTesting).map(([label, item], index) => (
                <div className={'flexRowBetween screeninTableRow '+(((index %4 == 2) || (index %4 == 3)) ? "grey":"")}>
                  <div className='rowHalf widthLarger'>{label}</div>
                  <div className='rowHalf widthShorter bold'>{item}</div>
                </div>
              ))}
            </div>
          </div>

          <div className='pad'>
              <div className='blueBar greyBar'>
                Office Use Only
              </div>
              <div className='table'>
                  {Object.entries(screeningData.officeUseOnly).map(([label, item], index) => (
                    <div className={'flexRowBetween screeninTableRow '+(((index %4 == 2) || (index %4 == 3)) ? "grey":"")}>
                      <div className='rowHalf'>{label+":"}</div>
                      <div className='rowHalf bold'>{item}</div>
                    </div>
                  ))}
                  <div className={'flexRowBetween screeninTableRow '}>
                    <div className='rowHalf'>{"Notes:"}</div>
                    <div className='rowHalf bold'>{"-"}</div>
                  </div>
                  <div className={'flexRowBetween screeninTableRow '}>
                    {/* <div className='rowHalf'>{"Notes"}</div> */}
                    {/* <div className='rowHalf bold'>{"NA"}</div> */}
                  </div>
              </div>
            </div>

        </div>

      </div>

    </div>
  )
}

export default Screening