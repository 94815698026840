import React from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

function CircleInfoBox({image, title, content, right, isMobileOverride}) {

  return (
    <div className={'circleInfoBox '+(isMobile?"":"circleInfoBoxDesktop ")+((right && !isMobile) ? " circleInfoBoxDesktopRight":"")}>
        <img src={image}></img>
        <div className={'circleInfoTextBox '+(isMobile ? "":" circleInfoTextBoxDesktop ") +((!isMobile && right) ? " circleInfoTextBoxDesktopRight":"")}>
            <h2 className='circleInfoTitle'>{title}</h2>
            <p className='circleInfoContent'>{content}</p>
        </div>
    </div>
  )
}

export default CircleInfoBox