import React from 'react'
import iceCubeImage from "../../Media/iceCubeFace2.png"
function IceBreaker({message}) {
  return (
    <div className='iceBreaker'>
      <img src={iceCubeImage} className='iceBreakerImg'></img>
      <div className='iceBreakerTxt'>
        {message}
      </div>
    </div>
  )
}
IceBreaker.defaultProps = {
  message: "If your not sure what to say just say hi"
}
export default IceBreaker