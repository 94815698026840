import React, { useEffect } from 'react'
import { setShowChat, setShowMenu, setViewProfileID, syncAppState } from '../../GlobalState/AppSlice'
import { useDispatch, useSelector } from 'react-redux'
import "./Windows.css"
import { Link } from 'react-router-dom'
import { addToActivityLog } from '../../Functions'

function Menu() {
    const userID = useSelector(state => state.appSlice.userID)
    const dispatcher = useDispatch()

    function closeMenu(e, dontCloseSearchProfile){
      // console.log("closeMenu dontCloseSearchProfile: "+dontCloseSearchProfile)
      dispatcher(setShowMenu(false))
      if(!dontCloseSearchProfile)
        dispatcher(setViewProfileID())
    }

    useEffect(()=>{
      addToActivityLog("Opened Menu", userID)
    },[])

  return (
    <>
        <Link to={"/search"} onClick={()=>{dispatcher(syncAppState()); closeMenu();}}>  
          <div className='menuButton'>
            Donor Search
          </div>
        </Link>
        <div className='menuButton' onClick={()=>{closeMenu(); dispatcher(setShowChat(true))}}>
          Chats
        </div>
        <Link to={"/profile/"+userID} onClick={()=>{dispatcher(syncAppState()); closeMenu();}} >  
          <div className='menuButton'>
            Profile
          </div>
        </Link>
        <Link to={"/"} onClick={()=>{dispatcher(syncAppState()); closeMenu();}}>
          <div className='menuButton' >
            Home
          </div>
        </Link>
    </>
  )
}

export default Menu