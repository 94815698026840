import React from 'react'
import backIcon from "../../Media/backIconBlue.png"
import "./Profile.css"
import { setViewProfileID } from '../../GlobalState/AppSlice'
import { useDispatch, useSelector } from 'react-redux'
import Profile2 from './Profile2'

function ProfileView() {

  const userID = useSelector(state => state.appSlice.userID)
  const viewProfileID = useSelector(state => state.appSlice.viewProfileID)
  const dispatcher = useDispatch()

  return (
    <> 
      {viewProfileID &&
        <div className='profileViewWindow'>
          <div 
              onClick={()=>dispatcher(setViewProfileID())} 
              className='profileViewTopBar'
              >
              <div className='profileSearchBackButton'>
                  <img className='profileViewTopBarBackIcon' src={backIcon}></img>
                  <div className='profileViewTopBarBackText' >
                      Back to search
                  </div>
              </div>
          </div>
          <div className='appScroller' id={'appScroller'+userID}>
              <Profile2  profileID={viewProfileID}></Profile2>
          </div>
        </div>
      }
  </>
  )
}

export default ProfileView