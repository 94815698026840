import React, { useEffect, useState } from 'react'
import { ellipsisString, getInfoForChat } from '../../Functions'
import { useDispatch, useSelector } from 'react-redux'
import { onValue, ref } from '@firebase/database'
import { db, setChatUserID } from '../../GlobalState/AppSlice'

function ChatBar({chatDataParam}) {

    let userID = useSelector(state => state.appSlice.userID)
    // This is chat metadata
    const [chatData, setChatData] = useState({})
    const [otherUserProfileData, setOtherUserProfileData] = useState()
    const dispatcher = useDispatch()

    useEffect(()=>{
      loadChat()
    },[chatDataParam])

    /*
      Put the data from the chatDataParam into a modified local state variable
    */
    function loadChat(){
      
      // Get the general chat data from the param
      let chatData = {...chatDataParam}
      
      // Get the ID of the other user
      chatData.otherUserID = getOtherUserID(chatDataParam)
      
      // Creates a listener that loads the profile data of the other uesr
      loadOtherUserProfileData(chatData?.otherUserID)

      // Save the chat data
      setChatData(chatData)

    }

    /**
      Gets the user ID from chatData.userIDs that does not match the user's userID
    */
    function getOtherUserID(chatData){
      if(!chatData?.userIDs || !Array.isArray(chatData?.userIDs)) return
      
      let otherUserIDTemp
      chatData?.userIDs.forEach(chatUserID => {
        if(chatUserID !== userID)
          otherUserIDTemp = chatUserID
      })
      return otherUserIDTemp
    }

    /**
      Load the profile data of the other user so it can be displayed
    */
    function loadOtherUserProfileData(otherUserID){
      if(!otherUserID) return
      onValue(ref(db, "freeFer/users/userProfiles/"+otherUserID), snap => {
        if(snap.val())
          setOtherUserProfileData(snap.val())
      })
    }
  
    function unreadMessage(chatData){
      // If its from the other user and not read return true (meaning that it is unread)
      if(chatData?.mostRecentMessage?.from !== userID && !chatData?.mostRecentMessage?.read) return true
      return false
    }

  return (
    <div 
      className={'chatBarChat '+chatData?.id} 
      // Why is this not just saving the chatID, can get the otherUserID easily and load it same as here
      onClick={()=>dispatcher(setChatUserID(chatData.otherUserID))} 
    >
        <div className='chatBarImage' key={"chatBarImage"+otherUserProfileData?.id}><img src={otherUserProfileData?.images?.main?.downloadURL}></img></div>
        <div className='chatBarLine'>{otherUserProfileData?.name || "Anon User"}</div>

        {/* <div className='chatBarLine'>{chatData?.otherUserProfileData?.name}</div> */}
        <div className={(unreadMessage(chatData) ? " unreadChatMessage":"")+' chatBarLine chatBarMessagePreview '}>
          {ellipsisString(chatData?.mostRecentMessage?.message, 28)}
          {unreadMessage(chatData) && <div className='unreadIndicator'></div>}
        </div>
    </div>
  )
}

export default ChatBar