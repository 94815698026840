import React from 'react'
import PageContiner from './PageContiner'
import Contact from './Contact'

function ContactPage() {
  return (
    <PageContiner>
        <Contact
          title={"Contact"}
          applicationName={"Contact"}
          description={( 
            <>
              <div>
                {"Email us at "} 
                <a href='mailto:Contact@FreedomFertility.com' className='contactEmailLink'>
                    Contact@FreedomFertility.com
                </a>
              </div>
              <div>Or submit a message below</div>
            </>
        )}
        ></Contact>
        {
        /* <div className='applyPageOuter'>
          <Apply 
            title={"Contact Us"}
            description={"If you have any questions or comments please fill out the box below and we will get back to you as soon as possible. You can also email us at Contact@FreedomFertility.com"}
            applicationName={"Contact"}
            textareaPrompt={"Type your message here"}
          >
          </Apply>
        </div> */}
    </PageContiner>
  )
}

export default ContactPage