import React, { useEffect, useRef } from 'react'
import { addToActivityLog } from '../../Functions';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

function PageActivity() {

        const userID = useSelector(state => state.appSlice.userID)
        
        const handleVisibilityChange = () => {
            
            if (document.hidden) {
                // User switched to a different tab
                addToActivityLog("User left tab ", userID)

            } else {
                // User came back to the page
                addToActivityLog("User returned to tab ",userID)
            }
        };
        const handleBeforeUnload = (event) => {

            addToActivityLog("User left app", userID)

        };
        
        useEffect(() => {
            // These will be setup before the userID is set without this
            if(!userID) return

            // For leaving the page
            window.addEventListener('beforeunload', handleBeforeUnload);
            // For leaving tab (and returning)
            document.addEventListener('visibilitychange', handleVisibilityChange);
        
            // Cleanup
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };

        }, [userID]);

        // URL path change log
        const location = useLocation();

        useEffect(() => {

            if(!userID) return
            addToActivityLog("Changed page: "+location.pathname, userID)

        // This will run whenever the path changes
        }, [location.pathname, userID]);
      
        // isMobile status
        useEffect(()=>{
            if(!userID) return
            addToActivityLog("isMobile: "+isMobile, userID)

        },[isMobile, userID])

}

export default PageActivity