import { push, ref, set } from '@firebase/database'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { db } from '../../GlobalState/AppSlice'
import { dateStringUTC } from '../../Functions'

function Contact({title, applicationName, description}) {

    const userID = useSelector(state => state.appSlice.userID)

    const [message, setMessage] = useState()
    const [submitted, setSubmitted] = useState()

    function submitApplication(){
        let inputData = {
            applicationName: applicationName || title || "unnamed application" ,
            name: document.getElementById("contactNameInput")?.value,
            phone: document.getElementById("contactPhoneInput")?.value,
            email: document.getElementById("contactEmailInput")?.value,
            message: document.getElementById("contactMessageInput")?.value,
            dateTime: dateStringUTC(),
            userID: userID,
        }
       
        if(inputData?.email === "" && inputData?.phone === ""){
            setMessage("Please provide at least one form of contact (email or phone)")
            return
        }

        setSubmitted(true)
        setMessage("Application Submitted.")

        let newApplicationRef = push(ref(db, "freeFer/admin/applications"))
        set(newApplicationRef, inputData) 

    }


  return (
    <div className='contactPageContainer'>
        <div className='contactPageCover'></div>
        <div className='contactBoxContainer'>
            <div className='contactBox'>
                <div className='contactTitle'>{title}</div>
               {description &&
                    <div className='contactText'>
                        {description}
                    </div>
                }
                <div>{message}</div>
                {submitted?
                    <div className='contactSubmittedMessage'>
                        {message}
                    </div>
                    :
                    <div>
                        <input className='contactInput' placeholder='Name' id='contactNameInput'></input>
                        <input className='contactInput' placeholder='Phone:' id='contactPhoneInput'></input>
                        <input className='contactInput' placeholder='Email:' id='contactEmailInput'></input>
                        <textarea className='contactInput contactTextArea' placeholder='Message' id='contactMessageInput'></textarea>
                        <div className='contactButtonContainser'>
                            <button className='contactButton' onClick={submitApplication}>Submit</button>
                        </div>
                    </div>                      
                }
            </div>
        </div>
    </div>
  )
}

export default Contact