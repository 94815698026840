import React from 'react'
import { useSelector } from 'react-redux'
import { readableDateString } from '../../Functions'

function Message({messageData, inFloatingWindow, chatData, otherUserProfileData}) {

  const userID = useSelector(state => state.appSlice.userID)

  return (
    <>
      {messageData?.dateDisplay?
        // A date display message just displays the date/time of a message group
        <div>
          {messageData.message}
        </div>
        :
        // A message displayed based on who it is from
        <div className={'chatMessageOuter '+((messageData?.from === userID) ? "chatMessageOuterRight":"chatMessageOuterLeft")} key={messageData?.id}>
          {(messageData?.from !== userID) && <div className='messageUserImage'><img src={otherUserProfileData?.images?.main?.downloadURL}></img></div>}
          <div className={'chatMessage '+((messageData?.from === userID) ? "chatMessageRight":"chatMessageLeft") + (inFloatingWindow ? " chatMessageFloating":"")} title={readableDateString(messageData.dateTime)}>
              <pre>
                  {messageData.message}
              </pre>
          </div>
        </div>
      }
    </>
  )
}

export default Message