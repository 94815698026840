import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Profile2 from './Profile2'
import Navbar2 from '../NavBar/Navbar2'

function ProfilePage() {
  const userID = useSelector(state => state.appSlice.userID)
  const {profileIDParam} = useParams()

  return (
    <>
      <Navbar2></Navbar2>
      <div className='appScroller' id={'appScroller'+userID}>
        <Profile2 profileID={profileIDParam}></Profile2>
      </div>
    </>
  )
}

export default ProfilePage