import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage'
import React, { useRef, useState } from 'react'
import { db, setDeleteImageObject, storage } from '../../GlobalState/AppSlice'
import editIcon from "../../Media/editIconSW.png"
import { ref as dbRef, set } from '@firebase/database'
import ImageViewer from '../Menu/ImageViewer'
import ConfirmationWindow from '../Menu/ConfirmationWindow'
import { dateString, deleteImage } from '../../Functions'
import { useDispatch, useSelector } from 'react-redux'

/*

    This component should recieve 2 URLs and an object
        where to save the image (imagePath)
        where to save the image path object (dataPath)
        imagePathObject: data about the currently saved image (if any, used to display or delete)

    imagePathObject: {
        storageURL: "",  (where the image is stored, used for deletion)
        downloadURL: "", (where the download URL is, used for display)
        name: "file name",
        uploadDate: "date the file was uploaded"
    }

    When a file is put into the input (checked in onChange)
    it is uplaoded based on provided   
    it saves the download url in the given inpagePath?

*/

function ImageUploader({imagePath, dataPath, imagePathObject, imageIdentifier}) {  
    
    const deleteImageObject = useSelector(state => state.appSlice.deleteImageObject)
    const dispatcher = useDispatch()
    const [progress, setProgress] = useState(0)
    const [previewUrl, setPreviewUrl] = useState()
    const [showProgressBar, setShowProgressBar] = useState()
    const [showImageWindow, setShowImageWindow] = useState()
    const imageInputRef = useRef()
    const storageURL = useRef()

    // ============================================================
    // #region DB Interaction: uploadImage, deleteImageFunction

    function uploadImage(file){
        // Make sure a valid file and paths are available 
        if(!imagePath || !dataPath || !file?.name) return        

        if(imagePath.includes("newUser")){
            console.log("Can't add image before creating user. Type into input fields first.")
            return
        }

        // Where the file wll be saved
        storageURL.current = imagePath + file?.name

        // Upload the image to imagePath
        let storageLocationRef = ref(storage, storageURL.current)
        const uploadTask = uploadBytesResumable(storageLocationRef, file)
        uploadTask.on("state_changed", snapshot => {
            const progress = 100 * (snapshot.bytesTransferred / snapshot.totalBytes)
            setProgress(progress)
            
            if(progress < 100)
                setShowProgressBar(true)
            else    
                setTimeout(() => {
                    setShowProgressBar(false)
                }, 2000)
        }, 
        e => {
            console.log("file upload error:")
            console.log(e)
            console.log(e?.message)
        },
        ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then(url => {
                // If there is already an image there delete it
                if(imagePathObject?.storageURL)                    
                    deleteImage(imagePathObject?.storageURL)

                let newImagePathObject = {
                    downloadURL: url, 
                    storageURL: storageURL.current,
                    dataPath: dataPath, 
                    name: file?.name,
                    uploadDate: dateString()
                }

                let newRef = dbRef(db, dataPath)
                set(newRef, newImagePathObject)
            })
        })
    }

    function confirmDeleteImage(e){
        e.stopPropagation()
        e.preventDefault()

        setPreviewUrl("")

        // This data is put in state causing a confirmation box in App.js to display, this data is then used delete the image if the user confirms
        let imageDeleteObject = {
            storageURL: imagePathObject.storageURL,
            profileDataPath: dataPath,
            name: imagePathObject?.name,
        }
        dispatcher(setDeleteImageObject(imageDeleteObject))
    }
 
    // #endregion DB Interaction

    // ============================================================
    // #region Image Uploader Interactions: fileDragOver, fileSelection, fileDrop, clickFunction

    function fileDragOver(e){
        e.preventDefault()
    }
    function fileSelection(e){

        // Get the file from the event
        let file = e.target.files[0]  

        // Create a preview url and display it
        setPreviewUrl(URL.createObjectURL(file))

        // Create a preview url and display it
        uploadImage(file)
    }
    function fileDrop(e){
        e.preventDefault()
        
        // Get the file from the event
        let file = e.dataTransfer.files[0]
        
        // Create a preview url and display it
        setPreviewUrl(URL.createObjectURL(file))
        
        // Upload the file (and show progress bar)
        uploadImage(file)

    }
    function clickFunction(e){
        if(previewUrl || imagePathObject?.downloadURL){
            e.preventDefault()
            setShowImageWindow(true)
        }
        // Else it will open the selector because that is the default behavior

    }
    function selectNewImageFunction(e){
        e.stopPropagation()
        e.preventDefault()

        imageInputRef.current?.click()
    }

    // #endregion

    return (
        <div className='imageUploader'>
            {showImageWindow &&
                <ImageViewer imageUrlArray={[imagePathObject?.downloadURL]} close={()=>setShowImageWindow(false)}></ImageViewer>
            }
            <input type='file' ref={imageInputRef} className='hidden' id={'imageInput'+imageIdentifier} onChange={fileSelection}></input>
            <label for={'imageInput'+imageIdentifier} onDragOver={fileDragOver} onDrop={fileDrop} className='imageSelectorLabel' onClick={clickFunction}>
                <div className='imageDropZone'>
                    
                    <img src={imagePathObject?.downloadURL || previewUrl}></img>                    
                    {(imagePathObject?.downloadURL || previewUrl) ? 
                        <>
                            <div className='bottomButton bottomButtonLeft' title='Delete Image' onClick={confirmDeleteImage}>x</div>
                            <div className='bottomButton bottomButtonRight' title='Change Image' onClick={selectNewImageFunction}>
                                <img src={editIcon}></img>    
                            </div>                            
                        </>
                        :
                        <div className='imageDropZoneMessage'> Add Image</div>                
                    }
                    {showProgressBar && 
                        <div className='progressBar'>
                            <div className='progressBarInner' style={{width: progress+"%"}}></div>                    
                            <div className='progressBarText'>
                                { Math.round(progress)+"%"}
                            </div>
                        </div>
                    }
                    <div className='closeButon'></div>
                </div>
            </label>

            {deleteImageObject && 
                <ConfirmationWindow 
                message={"Delete Image "+deleteImageObject?.name}
                confirm={()=>{deleteImage(deleteImageObject); dispatcher(setDeleteImageObject())}}
                cancel={()=>dispatcher(setDeleteImageObject())}
                ></ConfirmationWindow>
            }

        </div>
    )
}

export default ImageUploader