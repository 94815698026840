import React, { useEffect, useState } from 'react'
import ImageViewer from '../Menu/ImageViewer'
import { useDispatch } from 'react-redux'
import { openImageArrayViewer } from '../../GlobalState/AppSlice'
/*

    imagePathObject: {
        storageURL: "",  (where the image is stored, used for deletion)
        downloadURL: "", (where the download URL is, used for display)
        name: "file name",
        uploadDate: "date the file was uploaded"
    }

*/
function ProfileViewImages({profileData}) {

    const dispatcher = useDispatch()

    const [imageArray, setImageArray] = useState([])
    const [showImageViewer, setShowImageViewer] = useState()
    const [imageArrayStartingIndex, setImageArrayStartingIndex] = useState([])

    useEffect(()=>{
        setImageArrayFunction()
    },[profileData])

    function setImageArrayFunction(){
        if(typeof profileData?.images !== "object") return
        let tempImageArray = []
        Object.entries(profileData?.images).forEach(([imageIdentifier, imagePathObject]) => {
            // The main image goes first
            if(imageIdentifier === "main"){
                tempImageArray = [imagePathObject?.downloadURL, ...tempImageArray]
            }
            else{
                tempImageArray.push(imagePathObject?.downloadURL)
            }
        })
        
        setImageArray(tempImageArray)
    }

    function openImageArrayViewerFunction(startingIndex){
        dispatcher(openImageArrayViewer({imageArray: imageArray, index: startingIndex}))
    }

  return (
    <>
        {/* {showImageViewer && <ImageViewer startingIndex={imageArrayStartingIndex} imageUrlArray={imageArray} close={()=>setShowImageViewer(false)}></ImageViewer>} */}
        <div className='viewImageArea'>
            <div className='mainImage' onClick={()=>openImageArrayViewerFunction(0)}>
                <img src={profileData?.images?.main?.downloadURL}></img>
            </div>
            <div className='sideImages'>
                <div className='secondaryImage' onClick={()=>openImageArrayViewerFunction(1)}>
                    <img src={profileData?.images?.image1?.downloadURL}></img>
                </div>
                <div className='secondaryImage' onClick={()=>openImageArrayViewerFunction(2)}>
                    <img src={profileData?.images?.image2?.downloadURL}></img>
                </div>
            </div>
            <div>
                <div className='secondaryImage' onClick={()=>openImageArrayViewerFunction(3)}>
                    <img src={profileData?.images?.image3?.downloadURL}></img>
                </div>
                <div className='secondaryImage' onClick={()=>openImageArrayViewerFunction(4)}>
                    <img src={profileData?.images?.image4?.downloadURL}></img>
                </div>
                <div className='secondaryImage widthHide' onClick={()=>openImageArrayViewerFunction(5)}>
                    <img src={profileData?.images?.image5?.downloadURL}></img>
                </div>
            </div>
        </div>
        <div>
        </div>
    </>
  )
}

export default ProfileViewImages