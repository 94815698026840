import { onAuthStateChanged } from '@firebase/auth'
import { equalTo, onValue, orderByChild, push, query, ref, set } from '@firebase/database'
import React, { useEffect } from 'react'
import { auth, db, setChats, setProfileData, setSearchableProfiles, setUserID } from '../../GlobalState/AppSlice'
import { addToActivityLog, dateStringUTC, isValidObject, objectKeyToArray } from '../../Functions'
import { isCompositeComponent } from 'react-dom/test-utils'
import { useDispatch } from 'react-redux'

/*
    16-3-2024

    This is a more simple data loading solution

    If there is an auth user 
        looks for their data
            if there is data it is loaded
            if not it is created
    if there is no auth user
        looks in local storage for anon user
            if there is a saved ID looks for their data
                if there is data it is loaded
                if not it is created
            if there is not a saved ID creates on and sets default data

    When a user goes to the site an anon account will be generated automatically
        when they add profile data or create chats that will be saved in this anon account 
    
    There will be a login button
        when pressed it will load the data from the account and disregard any local anon account data
        when they log out the anon account data will be what displays

    Later will create a button for creating an account
    or linking local data to existing account
      both will move the local account data into the created account

    The only thing this will save as far as admin data is the ip address, login date, and last active, which will all be saved in profile data

*/
function LoadData3() {

    const dispatcher = useDispatch()

    useEffect(()=>{
        startAuthListener()
        loadDonors()
    },[])

    /**
     * Start an auth listener that loads user data when the user is logged in
     */
    function startAuthListener(){
        onAuthStateChanged(auth, user => {

            // If there is an account user logged in load their data
            if(user) {
                loadUserData(user?.uid)
                // updateLoginLog(user?.uid)

            }
            // If there is no account user logged in
            else {

                // Look in local storage for an anonUserID
                let anonUserID
                
                try{
                    anonUserID = window.localStorage.getItem("anonUserID")
                }catch{
                    addToActivityLog("window.localStorage.getItem Error")
                }
                
                // If there is an anon account load the corresponding data from the db
                if(anonUserID){
                    loadUserData(anonUserID)
                    // updateLoginLog(anonUserID)
                    addToActivityLog("returningAnonUser", anonUserID)

                }
                // If there is no data create the account in the db
                else {
                    // Create a new userID
                    const newUserRef = push(ref(db, "freeFer/users/userProfiles"))
                    const newAnonUserID = newUserRef.key

                    addToActivityLog("createdAnonUser", newAnonUserID)

                    // Attempt to load it (there will be no data so default data will be set)
                    loadUserData(newAnonUserID)

                    try{
                        // Put it in local storage
                        window.localStorage.setItem("anonUserID", newAnonUserID)

                    }catch{
                        let newRef = push(ref(db, "matchApp/errors"))
                        set(newRef, {
                            error: "window.localStorage", 
                            datetime: dateStringUTC(),
                        })
                    }



                }


            }

        })
    }

    /**
     * Load the user profiles of the donors so they can be searched by the user
     */
    function loadDonors(){

        /*

        TODO:
        WARNING: Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ".indexOn": "userType" at /freeFer/users/userProfiles to your security rules for better performance. 

        */

        const userProfilesRef = ref(db, "freeFer/users/userProfiles")

        const donorsQuery = query(userProfilesRef, orderByChild('userType'), equalTo('donor'))
        onValue(donorsQuery, snap => {
            
            // Get the data from the existing db structure
            let allDonorsJSON = snap.val()

            if(!isValidObject(allDonorsJSON)) return
            
            setTimeout(() => {   
                dispatcher(setSearchableProfiles(allDonorsJSON))
            }, 50);
            
        })
    }

    /**
        Loads user data from userProfiles and userChats
    */
    function loadUserData(userID){
        
        if(!userID) return

        dispatcher(setUserID(userID))

        // Load the user's profile data
        onValue(ref(db, "freeFer/users/userProfiles/"+userID), snap => {

            // If there is data load it
            if(snap.val()){    

                // Put existing data into store
                setTimeout(() => {   
                    dispatcher(setProfileData(snap.val()))
                }, 50);

            }
            // If there is no data create some and put it in the database
            else{
                initialAccountData(userID)
            }
        })

        // Load the users chats data
        onValue(ref(db, "freeFer/users/userChats/"+userID), snap => {
            // If there is chat data load it
            if(snap.val()){     
                // Put existing data in to store
                setTimeout(() => {   
                    dispatcher(setChats(snap.val()))
                }, 50);
                
            }
        })
    }

    /**
        Update last active datetime, add to login log to admin data
    */
    function updateLoginLog(userID){
        if(!userID) return

        // Set the last active date
        set(ref(db, "freeFer/users/userProfiles/"+userID+"/lastActive"), dateStringUTC())

        try{
            // IP datetime 
            fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                // console.log("logged in on "+dateStringUTC()+" from "+data.ip)
                set(ref(db, "freeFer/users/userProfiles/"+userID+"/loginLog/"+dateStringUTC()), data.ip)
                // Update dash later
                // set(ref(db, "freeFer/users/userAdminData/"+userID+"/loginLog/"+dateStringUTC()), data.ip)
            })
            .catch(e=>{
                set(ref(db, "freeFer/users/userProfiles/"+userID+"/loginLog/"+dateStringUTC()), "ip error")
            });

        }catch{
            set(ref(db, "freeFer/users/userProfiles/"+userID+"/loginLog/"+dateStringUTC()), "ip error catch")

        }
    }

    /**
        Creates initial account data for a new user
    */
    function initialAccountData(userID){
        if(!userID) return

        // Put default data in the new userID 
        let defaultProfileData = {
            name: "Anon User "+getLastFiveCharacters(userID),
            lastActive: dateStringUTC(),
            startDate: dateStringUTC(),
        }

        // Save that data to the store as the new users data
        set(ref(db, "freeFer/users/userProfiles/"+userID), defaultProfileData)

        // updateLoginLog(userID)

        setTimeout(() => {   
            dispatcher(setProfileData(defaultProfileData))
        }, 50);

    }

    function getLastFiveCharacters(str) {
        // Check if input is a string
        if (typeof str !== 'string') {
          throw new Error('Input must be a string.');
        }
        
        // Check if string length is at least 5 characters
        if (str.length < 5) {
          throw new Error('String must be at least 5 characters long.');
        }
        
        // Return the last 5 characters of the string
        return str.slice(-5);
    }
      
    // #region dev functions

    // Dev function to determine if a profile is empty
    function isEmptyProfile(userData){
        if(!userData) return true
        if(!userData?.profileData) return true
        if(typeof userData?.profileData !== "object") return true
        if(Object.values(userData?.profileData).length == 1 && userData?.profileData?.name === "anonymous"){
            return true
        }
        return false

    }

    /*
        One time use dev function used to transfer all of the profiles from the old db structure to the new one
        
        old structure:
        freeFer/allUsers/
            donors/
                <userID>/
                    profileData/
                    chats/
            users/
                <userID>/
                    profileData/
                    chats/

        new structure:
            freeFer/users/
                userProfiles/
                    <userID>/
                        <profileData>
                userChats/
                    <chatsData>

    */
    function profileTransfer(){
        onValue(ref(db, "freeFer/allUsers"), snap => {
            
            // Get the data from the existing db structure
            let allUsersJSON = snap.val()

            console.log("allUsersJSON:")
            console.log(allUsersJSON)

            // Combine it into one json
            let allUsersCombined = {}
            allUsersCombined = {...allUsersCombined, ...allUsersJSON.donors}
            // Pulling this out for testing on the smaller set
            allUsersCombined = {...allUsersCombined, ...allUsersJSON.users}

            console.log("allUsersCombined:")
            console.log(allUsersCombined)

            // Maybe clean it so the profiles with no real data are not put in the new db structure
            // Keep them for now, can analyze them in the admin dash
            // Still don't know why there are 400+ anon accounts with no data, 
            //  are people visiting it, is the hosting site creating these somehow, something else?

            let filteredUsers = {}
            Object.entries(allUsersCombined).forEach(([userID, userData]) => {
                if(isEmptyProfile(userData)){
                    // Dont add it 
                }
                else {
                    filteredUsers[userID] = userData
                }
            })
            console.log("filteredUsers:")
            console.log(filteredUsers)

            // Pull out the profile data and chats for each user
            let allUsersProfileData = {} 
            let allUsersChats = {} 
            Object.entries(filteredUsers).forEach(([userID, userData]) => {
                if(userData?.profileData)
                    allUsersProfileData[userID] = userData?.profileData
                if(userData?.chats)
                    allUsersChats[userID] = userData?.chats
            })

            console.log("allUsersProfileData:")
            console.log(allUsersProfileData)
            console.log("allUsersChats:")
            console.log(allUsersChats)

            let newDbStructure = {
                userProfiles: allUsersProfileData,
                userChats: allUsersChats,
            }
            console.log("newDbStructure:")
            console.log(newDbStructure)

            set(ref(db, "freeFer/users"), newDbStructure)

        })
    }

    // #endregion dev functions

  return (
    <></>
  )
}

export default LoadData3