import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ImageUploader from './ImageUploader'
/*
    This component:
        Loads user image path objects
        Creates URLs for each ImageUploader
            Where to save the image
            Where to save the new imagePathObject

    imagePathObject: {
        storageURL: "",  (where the image is stored, used for deletion)
        downloadURL: "", (where the download URL is, used for display)
        name: "file name",
        uploadDate: "date the file was uploaded"
    }
    imagePathObject saved in freefer/users/userID/userData/images/imageIdentifier

*/
function ProfileImages2({userIDOverride}) {

    const userID = useSelector(state => state.appSlice.userID)
    const profileData = useSelector(state => state.appSlice.profileData)
    const searchableProfiles = useSelector(state => state.appSlice.searchableProfiles)

    // Where the image will be saved
    function imagePath(){
        return "freefer/userImages/" + (userIDOverride || userID)+"/"     
    }

    // Where the imagePathObject will be saved
    function dataPath(imageIdentifier){
        if(!imageIdentifier){
            console.log("need image identifier for data path")
            return
        }
        return "freeFer/users/userProfiles/"+(userIDOverride || userID)+"/images/"+imageIdentifier

    }
    // Getting the existing image path object for the user
    function imagePathObject(imageIdentifier){
        if(!imageIdentifier){
            console.log("need image identifier for imagePathObject")
            return
        }        

        if(userIDOverride === userID){
            let userImages = profileData?.images
            if(!userImages) return {}
    
            let imageObject = userImages[imageIdentifier]
            return imageObject
            
        }
        else{
            
            if(!searchableProfiles || typeof searchableProfiles !== "object") return {}

            let userImages = searchableProfiles[userIDOverride]?.images 
            if(!userImages) return {}
    
            let imageObject = userImages[imageIdentifier]
            return imageObject
        }

    }


  return (
    <div className='imageArea'>
        <div>
            <div className='mainImage'>
                <ImageUploader
                    // The path to save the image   
                    imagePath={imagePath()}
                    // The path to save the image URLs   
                    dataPath={dataPath("main")}
                    // The object wiht data abou the previously uploaded image
                    imagePathObject={imagePathObject("main")}
                    // Used to set IDs for the input label
                    imageIdentifier="main"                
                    // The size of the image uploader    
                    size={{height: "220px", width: "220px"}} 
                ></ImageUploader>                        
            </div>
            <div className='sideImages'>
                <div className='secondaryImage'>
                    <ImageUploader
                        // The path to save the image   
                        imagePath={imagePath()}
                        // The path to save the image URLs   
                        dataPath={dataPath("image1")}
                        // The object wiht data abou the previously uploaded image
                        imagePathObject={imagePathObject("image1")}
                        // Used to set IDs for the input label
                        imageIdentifier="image1"                
                        // The size of the image uploader    
                        size={{height: "100px", width: "100px"}} 
                    ></ImageUploader>
                </div>
                <div className='secondaryImage'>
                    <ImageUploader
                        // The path to save the image   
                        imagePath={imagePath()}
                        // The path to save the image URLs   
                        dataPath={dataPath("image2")}
                        // The object wiht data abou the previously uploaded image
                        imagePathObject={imagePathObject("image2")}
                        // Used to set IDs for the input label
                        imageIdentifier="image2"                
                        // The size of the image uploader    
                        size={{height: "100px", width: "100px"}} 
                    ></ImageUploader>
                </div>
            </div>
        </div>
        <div>
            <div className='secondaryImage'>
                <ImageUploader
                    // The path to save the image   
                    imagePath={imagePath()}
                    // The path to save the image URLs   
                    dataPath={dataPath("image3")}
                    // The object wiht data abou the previously uploaded image
                    imagePathObject={imagePathObject("image3")}
                    // Used to set IDs for the input label
                    imageIdentifier="image3"                
                    // The size of the image uploader    
                    size={{height: "100px", width: "100px"}} 
                ></ImageUploader>
            </div>
            <div className='secondaryImage'>
                <ImageUploader
                    // The path to save the image   
                    imagePath={imagePath()}
                    // The path to save the image URLs   
                    dataPath={dataPath("image4")}
                    // The object wiht data abou the previously uploaded image
                    imagePathObject={imagePathObject("image4")}
                    // Used to set IDs for the input label
                    imageIdentifier="image4"                
                    // The size of the image uploader    
                    size={{height: "100px", width: "100px"}} 
                ></ImageUploader>
            </div>
            <div className='secondaryImage widthHide'>
                <ImageUploader
                    // The path to save the image   
                    imagePath={imagePath()}
                    // The path to save the image URLs   
                    dataPath={dataPath("image5")}
                    // The object wiht data abou the previously uploaded image
                    imagePathObject={imagePathObject("image5")}
                    // Used to set IDs for the input label
                    imageIdentifier="image5"                
                    // The size of the image uploader    
                    size={{height: "100px", width: "100px"}} 
                ></ImageUploader>
            </div>
        </div>
    </div>
  )
}

export default ProfileImages2