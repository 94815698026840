import React, { useEffect, useState } from 'react'
import UserTile from './UserTile'
import { useDispatch, useSelector } from 'react-redux'
import "./PagedUsers.css"
import { updateApplicationState } from '../../GlobalState/AppSlice'

function PagedUsersComponent({usersArray, userApplicationState, resetPagerCounter}) {

    const profileData = useSelector(state => state.appSlice?.profileData)
    const dispatcher = useDispatch()
    // const [pageRange, setPageRangeState] = useState([0, (profileData?.userType === "donor" ? 16:6)])
    const [pageRange, setPageRangeState] = useState([0, 6])
    const [pagedUsers, setPagedUsers] = useState([])
    const [count, setCount] = useState(usersArray?.length)

    useEffect(()=>{
        setPageRangeState([0, 6])
    },[resetPagerCounter])

    // Intercepts the set state function so the userApplicationState can be updated
    function setPageRange(newPageRange){
        
        dispatcher(updateApplicationState({searchPageRange: newPageRange}))
        setPageRangeState(newPageRange)
    }

    // If there is userApplicationState get the page range from there 
    useEffect(()=>{
        if(userApplicationState?.searchPageRange){
            setPageRangeState(userApplicationState.searchPageRange)
        }
    },[userApplicationState])

    useEffect(()=>{
        pageUsers()
        setPageRangeValues()
        setCount(usersArray?.length)
    },[usersArray, pageRange])

    function pageUsers(){
        let tempPagedUsers = []
        usersArray.forEach((userData, index) => {
            if(index >= pageRange[0] && index < pageRange[1])
                tempPagedUsers.push(userData)
        })
        
        // setPagedUsers(x)
        setPagedUsers(tempPagedUsers)
    }
    function setPageRangeValues(){
        let startInput = document.getElementById("pageRangeStart")
        let endInput = document.getElementById("pageRangeEnd")
        if(startInput)
            startInput.value = pageRange[0]
        if(endInput)
            endInput.value = pageRange[1]
    }

    function pageNext(){
        let diff = pageRange[1] - pageRange[0]
        let newStart = pageRange[0] + diff
        let newEnd = pageRange[1] + diff
        if(newStart >= count)
            return
        setPageRange([newStart, newEnd])

        // Reset the scroll
        let appScroller = document.querySelector(".appScroller")
        if(appScroller)
            appScroller.scrollTo(0, 0)
    }
    function pageBack(){
        let diff = pageRange[1] - pageRange[0]
        let newStart = pageRange[0] - diff
        let newEnd = pageRange[1] - diff
        if(newStart < 0){
            newStart = 0
            newEnd = diff
        }
        setPageRange([newStart, newEnd])

        if(newStart == 0){
            let appScroller = document.querySelector(".appScroller")
            if(appScroller)
                appScroller.scrollTo(0, 0)
        }
    }
    function toStart(){
        setPageRange([0, 12])
    }
    function updatePageRange(){
        let start = document.getElementById("pageRangeStart").value
        let end = document.getElementById("pageRangeEnd").value
        try{
            start = parseInt(start)
            end = parseInt(end)

            // For invalid inputs
            if(isNaN(start))
                start = pageRange[0]
            if(isNaN(end))
                end = pageRange[1]
                
            if(start == pageRange[0] && end == pageRange[1]) return

            setPageRange([start, end])
        }catch(e){
            console.log("invalid page range input")
        }
    }
    return (
        <div className='pagedUsersContainer'>
            <div className='pagerButtons' title={'Showing '+(pageRange[0] + 1)+' to '+ pageRange[1]+' of ' + count + ' results'}>
                <div>
                    <div title="Back" onClick={pageBack} className='rangeButton'>{"<"}</div>
                    {(pageRange[0] + 1) + " - " + pageRange[1] + " of " + count}
                    <div title="Next" onClick={pageNext} className='rangeButton'>{">"}</div>
                </div>
            </div>

            {pagedUsers.map((profileData) => (
                <UserTile 
                    // The profile data of the other user
                    profileData={profileData}
                    // Would prefer this to just be in profileData and map the profiles, but would need to change the filter component which may be more complicated than would be worth it
                    tileUserID={profileData.id} 
                    key={profileData.id} 
                    userApplicationState={userApplicationState}
                ></UserTile>
            ))}
        </div>
    )
}

PagedUsersComponent.defaultProps = {
    userSelected: (userID)=>{console.log("User Selected: "+userID)},
}

export default PagedUsersComponent