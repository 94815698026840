import React from 'react'
import PositionDisplay from './PositionDisplay'
// import backIcon from "../../Images/backIconBlue.png"
import backIcon from "../../Media/backIconBlue.png"

function PositionDisplayWindow({positionDataParam, startingTab, setSelectedPositionData}) {
  return (
    // <div className='topNavOffset'>
        <div className='mobileWindow '>
            <div className='topRowButton' onClick={()=>setSelectedPositionData()}>
                <img className='topRowButtonIcon' src={backIcon}></img>
                <div className='topRowButtonText'>
                    Back To Positions
                </div>
            </div>
            <PositionDisplay
                positionDataParam={positionDataParam}
                startingTab={startingTab}
            ></PositionDisplay>
        </div>
    // </div>
  )
}

export default PositionDisplayWindow