import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openFloatingChat, setChatUserID } from '../../GlobalState/AppSlice'

function UserSearchResult({ profileData, openChatWithUser}) {
    const thisUserProfileData = useSelector(state => state.appSlice.profileData)
    const dispatcher = useDispatch()

  return (
    <div className='userSearchResult' onClick={()=>dispatcher(setChatUserID(profileData?.id))} key={profileData?.id} title={profileData?.userType === "donor" ? ((profileData?.note || "no note")) : ""}>
        <div className='userSearchResultImage'>
            <img src={profileData?.images?.main?.downloadURL}></img>
        </div>
        <div className='userSearchResultName'>{profileData?.name}</div>
    </div>
  )
}

export default UserSearchResult