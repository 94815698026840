import React, { useEffect, useId, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { db, openFloatingChat, setAccountViewID, setchatUserID, setShowChatsWindow, toggleshowChat } from '../../GlobalState/AppSlice'
import { dateString, dateStringDate, dateStringUTC, getInfoForChat, sortByDate } from '../../Functions'
import { endBefore, limitToLast, onValue, orderByChild, push, query, ref, set, update } from '@firebase/database'
import openInWindowIcon from"../../Media/openInWindowIconBlue.png"
import backIcon from"../../Media/backIconBlue.png"
import minimizeIcon from"../../Media/minimizeIconBlue.png"
import { backToChats, db, setAsRead, setChatUserID, setOldUserID, setSelectedChatID, setShowChat, setViewProfileID } from '../../GlobalState/AppSlice'
import ChatMessages from './ChatMessages'
import { Link } from 'react-router-dom'

/*
  It is better to user chatOtherUserID

  clicking on the chat icon on a user's profile will pull up that that chat without needing to know the chatID 
  and it will still work if there is no chat, if there is a chatUserID and not chat then we know its a new chat

  when there is a chatOtherUserID change
    look for a chat with that user, save the chatID
    load the other user's profile data
  
  when the chatID changes (because the chatOtherUserID changed)
    load the messages for that chat (sorting them and adding date markers)

  Also mark the chat as read if the last message is from the other user

  Message sending is handled in a different component
  

*/
function Chat() {

  // Load chat data from chatID then profileData from chatData.otherUserID
  // Or load profileData from data from newChatUserID

  // One of these 2 will be set depending on if its an existing chat or a new chat
  const chatOtherUserID = useSelector(state => state.appSlice.chatUserID)
  const userID = useSelector(state => state.appSlice.userID)
  const profileData = useSelector(state => state.appSlice.profileData)
  const chats = useSelector(state => state.appSlice.chats)
  const dispatcher = useDispatch()
  
  const [chatID, setChatID] = useState()

  // The messages loaded from the database
  const [messages, setMessages] = useState([])
  const [loading, setLoading] =  useState(true)
  const [otherUserProfileData, setOtherUserProfileData] = useState()
  
  // ============================================================
  // #region Loading data to display

  // When the chatOtherUserID changes find and load the corresponding data
  useEffect(()=>{
    // Find the chat ID from the chatOtherUserID and userData.chats
    setChatID(getChatID(chatOtherUserID))
    // Load the other user's profile data when the other user changes
    loadOtherUserProfileData(chatOtherUserID)
    // If they are in the chat mark the chat as read initially and every time the chat changes (if a new message is added)
    markAsRead(chatID)
  },[chatOtherUserID, chats])

  // When the chatID changes load the chat data (messages)
  useEffect(()=>{
    loadMessages(chatID)
    markAsRead(chatID)
  },[chatID])

  // Get the chat ID from the user's chats data. If none is found sending a message will create a new chat.
  function getChatID(chatOtherUserID){
    // Set this to null incase the chatOtherUserID changes and there is no corresponding chat
    setChatID()

    // Look in the user's chats to find the chatID that has the chatOtherUserID in it
    let foundChatID
    let foundUserChatData
    if(chats && typeof chats === "object"){
      Object.entries(chats).forEach(([userDataChatID, userDataChatData]) => {
        // If the chatOtherUserID is found in the chatData.userIDs select that chat by putting its ID in state
        if(userDataChatData?.userIDs?.includes(chatOtherUserID)){
          foundUserChatData = userDataChatData
          foundChatID = userDataChatID
        }
        
      })
    }

    // Return the found chatID
    return foundChatID

  }

  // When the other userID changes load the other user's profile data
  function loadOtherUserProfileData(chatOtherUserID){
    onValue(ref(db, "freeFer/users/userProfiles/"+chatOtherUserID), snap => {
      setOtherUserProfileData(snap.val())
    })
  }

  function loadMessages(){

    // If there is not chatID there is nothing to load
    if(!chatID) return

    const messagesRef = ref(db, "freeFer/chats/"+chatID+"/messages")

    // This would work but would require loading all of them, would be better if it just loaded the 20 before the most recent 20 and pushed them to the existing array
    // last20MessagesQuery = query(messagesRef, orderByChild("datetime"), limitToLast(20 + ((index || 0) * 20)));
    
    let last20MessagesQuery
    // If there are no messages loaded load the most recent ones
    
    // For testing
    // last20MessagesQuery = query(messagesRef, orderByChild("datetime"), limitToLast(20));

    if(messages.length == 0)
      // This tells the database to load the 20 latest messages
      last20MessagesQuery = query(messagesRef, orderByChild("datetime"), limitToLast(20));
    // If there are messages loaded (the function is being called again) load 20 more
    else
      if(messages[0]?.id)  
        // This tells the database to load the latest starting before the oldest loaded message
        last20MessagesQuery = query(messagesRef, orderByChild("datetime"), endBefore(messages[0]?.messageID), limitToLast(20));
      else
        return
       
    // Load the messages from the db based on the chatID
    onValue(last20MessagesQuery, snap => {      
      
      setLoading(false)

      let messagesObject = snap.val()

      // If there are no messages in the chat or no additional messages
      if(!messagesObject || typeof messagesObject !== "object"){
        // setMessages([])
        return
      } 

      // Turn the messages object into a sorted array of objects with the messageID in the object
      let tempMessages = []
      Object.entries(messagesObject).forEach(([messageID, messageData]) => {
        tempMessages.push({
          ...messageData,
          id: messageID
        })
      })

      // Get a sorted array of all of the messages, the newly loaded ones and the previously loaded ones
      tempMessages = sortMessages([...tempMessages, ...messages])

      // Put it in state to be displayed
      setMessages(tempMessages)

    })

  }

  // Helper functions

  function sortMessages(messagesArray) {

    if(!messagesArray || !Array.isArray(messagesArray))
      return []

    // Make a local copy because the date message functions would not have access to the messagesArray parameter
    let tempMessagesArray = [...messagesArray]

    // Sort messages by datetime
    tempMessagesArray.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

    // Clearing old date messages to avoid duplicates
    tempMessagesArray = clearDateMessages(tempMessagesArray)

    // Adding new date messages
    tempMessagesArray = addDateMessages(tempMessagesArray)
   
    return tempMessagesArray
  }

  function clearDateMessages(messagesArray){
    if(!messagesArray || !Array.isArray(messagesArray))
      return []

    let tempMessages = []

    messagesArray.forEach(messageObject => {
      if(!messageObject?.dateDisplay)
        tempMessages.push(messageObject)
    })

    return tempMessages
    
  }

  function addDateMessages(messagesArray){

    if(!messagesArray || !Array.isArray(messagesArray))
      return []

    let tempMessages = []
    let lastDate = null;
  
    messagesArray.forEach(messageObject => {
      const messageDate = dateStringDate(messageObject?.datetime);
      if (messageDate !== lastDate) {
          // Insert a new message object for the date change
          tempMessages.push({
            id: messageObject?.datetime,
            message: messageDate,
            dateDisplay: true
          });
          lastDate = messageDate;
      }
      tempMessages.push(messageObject);
    });

    return tempMessages
  
  }

  // #endregion Loading data to display

  // ============================================================
  // #region Sending and updating DB

  function sendMessageDB(message){
    console.log("sending message: "+message)
  }

  function addMessageDB(message){
    
    // If there is a chat ID add a new message to the chat
    if(chatID){

      // Create a place for the new message
      let newMessageRef = push(ref(db, "freeFer/chats/"+chatID+"/messages"))

      // Push the message to the chat
      set(newMessageRef, {
        message: message || "",
        from: userID || "noUserID",
        datetime: dateStringUTC(),
      })

      // Set the most recent message in the other user's chat data
      set(ref(db, "freeFer/users/userChats/"+chatOtherUserID+"/"+chatID+"/mostRecentMessage"), {
        message: message,
        from: userID,
        datetime: dateStringUTC(),
        read: false,
      })

      // And the most recent message in this user's chat data
      set(ref(db, "freeFer/users/userChats/"+userID+"/"+chatID+"/mostRecentMessage"), {
        message: message,
        from: userID,
        datetime: dateStringUTC(),
        read: false,
      })

      // // Put a notification in the other user's notifications to show a new message has come in
      // set(ref(db, "matchApp/users/"+chatOtherUserID+"/notifications/newMessages/"+chatID), {
      //   message: message,
      //   from: userID,
      //   datetime: dateStringUTC(),
      // })

      if(otherUserProfileData?.ios){
        sendIOSMessageNotification(userID, chatOtherUserID, chatID, message, otherUserProfileData?.active)
      }
      else{
        // sendAndroidMessageNotification(userID, chatOtherUserID, chatID, message, otherUserProfileData?.active)
      }

    }
    // If there is not chatID create a new chat
    else{

      // Create the new chat in the db
      let newChatRef = push(ref(db, "freeFer/chats"))
      
      // Set the initial value
      set(newChatRef, {
        userIDs: [userID, chatOtherUserID],
        // Add the ice breaker so it will show the same one that was there when the first message was sent for both users
        // iceBreaker: iceBreaker, 
        messages: {
          firstMessage: {
            message: message,
            from: userID,
            datetime: dateStringUTC(),
            read: false,
          }
        }
      })
      
      // Add the chat to the other user's chat data
      set(ref(db, "freeFer/users/userChats/"+chatOtherUserID+"/"+newChatRef.key), 
        {
            userIDs: [userID, chatOtherUserID],
            // iceBreaker: iceBreaker,
            mostRecentMessage: {
              message: message,
              from: userID,
              datetime: dateStringUTC(),
              read: false,
            }
        }
      )

      // And add the chat to this user's chat data
      set(ref(db, "freeFer/users/userChats/"+userID+"/"+newChatRef.key), 
        {
          userIDs: [userID, chatOtherUserID],
          // iceBreaker: iceBreaker,
          mostRecentMessage: {
            message: message,
            from: userID,
            datetime: dateStringUTC(),
            read: false,
          }
        }
      )

      if(otherUserProfileData?.ios){
        sendIOSMessageNotification(userID, chatOtherUserID, newChatRef.key, message, otherUserProfileData?.active)
      }
      else{
        // sendAndroidMessageNotification(userID, chatOtherUserID, chatID, message, otherUserProfileData?.active)
      }

    }
  }

  function sendIOSMessageNotification(senderUserID, recipientUserID, chatID, newMessage, otherUserActiveStatus){    

    try{
      fetch("https://chat-api-02zq.onrender.com/iosNotify2", {
      // fetch("http://localhost:8080/iosNotify2", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        // Send the messages array as the post bodyhey
        body: JSON.stringify({
          senderUserID: senderUserID, 
          senderName: profileData?.name || false,
          recipientUserID: recipientUserID,
          chatID: chatID,
          type: "New Message", 
          newMessage: newMessage || "",
          otherUserActive: otherUserActiveStatus || false,
        })
      })

    }catch{
      console.log("IOS notification post catch.")
    }
  }

  function markAsRead(chatID){
    // Ensure valid IDs
    if(!chatID || !userID || !chatOtherUserID) return
    
    // Get the data for this chat
    let userChatData = chats[chatID]
    if(!userChatData) return

    // If the most recent message is not from this user and it is not read mark it as read
    if(
        userChatData?.mostRecentMessage?.from !== userID && 
        !userChatData?.mostRecentMessage?.read
    ){
      // Mark it as read for this user
      set(ref(db, "freeFer/users/userChats/"+chatOtherUserID+"/"+chatID+"/mostRecentMessage/read"), true)
      
      // Mark it as read for the other user
      set(ref(db, "freeFer/users/userChats/"+userID+"/"+chatID+"/mostRecentMessage/read"), true)
    
    }
  }

  // #endregion Sending and updating DB

  return (
    <>
        <div className='chatTopBar'>
            <>
              <div className='imageButtonSize imageButtonPosition2' title="Back to Chats" onClick={()=>dispatcher(setChatUserID())}>
                <div className='imageButtonImage'>
                  <img src={backIcon}></img>
                </div>
                <div className='imageButtonLabel'>Back to Chats</div>
              </div> 
              <div className="imageButtonPosition imageButtonSize" title="Close Chats Window" onClick={()=>{dispatcher(setShowChat(false)); dispatcher(setChatUserID())}}>
                <div className='imageButtonImage2'>
                  <img src={minimizeIcon}></img>
                </div>
                <div className='imageButtonLabel'>Close</div>
              </div>             
            </>

            <Link to={"/profile/"+chatOtherUserID} onClick={()=>{dispatcher(setShowChat()); dispatcher(setChatUserID())}}>
              <div className='chatUserInfo' >
                <div className='chatUserImage'>
                  <img src={otherUserProfileData?.images?.main?.downloadURL}></img>
                </div>
                <div className='chatUserName'>{otherUserProfileData?.name || "Anon User"}</div>
              </div>
            </Link> 
        </div>
        <ChatMessages messages={messages} sendMessageDB={addMessageDB} otherUserProfileData={otherUserProfileData} loadMoreMessages={loadMessages}></ChatMessages>
    </>
      
  )
  
}
Chat.defaultProps = {
  // showBackToChats: true,
}
export default Chat