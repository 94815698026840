export const companyData = {
    "Freedom-Fertility": {
      name: "Freedom Fertility",
      description: `
Funded by the Sarah Johnson Foundation for Human Development all of our services are provided completely free of charge. These services are available to everyone regardless of economic status or orientation.

One of our founding beliefs is that everyone should be able to experience the joy and purpose of parenthood regardless of their orientation. We aim to make this more accessable to everyone. Due to the nature of our service many of our users are members of the LGBT community so if you are member of this community you can rest assured you are very welcome.

The services provide on our platform enable intended parents to start their journey.

      `,
      reviews: [
        {
          reviewer: "Anonymouse User",
          rating: 5,
          comment: "Really nice people working here and I like their mission"
        },
        {
          reviewer: "Anonymouse User",
          rating: 4,
          comment: "Liked it there."
        },
        {
          reviewer: "Anonymouse User",
          rating: 5,
        },
      ],
      openPositions: {
        // React-Developer
        "React-Developer": {
          title: "React Developer",
          description: "Seeking front end / full stack react developers with 2-5 years of experience",
          // The company name and companyID should be obtained when parsing this into tiles that are displayed so the name displays properly and the user can click them to go to the compnay page
          
          hilights: `
Qualifications:

  • 2-5 years of experience with React or similar front-end frameworks
  • Strong proficiency in CSS and experience with no-sql databases
  • Ability to build responsive UI components and robust user interfaces
  • Passion for clean code and attention to detail

Benefits:

  • Competitive salary range (85k - 125k) with performance bonuses
  • Opportunities for remote work and flexible scheduling
  • Comprehensive health and wellness benefits
  • Collaborative and supportive team environment
        `,
            descriptionLong: `
            As a React Developer you will be at the forefront of designing cutting-edge web applications. Your role will involve creating dynamic components, resolving bugs, enhancing existing functionalities, and integrating APIs into our platform that assists people who are seeking family planning services. CSS skills and experience with no-sql databases is a plus.
        `,
        payRate: "85k - 125k",
        tags: ["85k - 125k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
      },
      // Database-Design
      "Database-Design": {
        title: "Database Design / Admin",
        description: "Seeking no-sql database administrators with 2-5 years of experience",
        
        hilights: `
Qualifications:

  • 2-5 years of experience in database design and administration
  • Proficient with no-sql databases and data modeling
  • Familiarity with React-Redux framework for front-end integration
  • Strong problem-solving skills and attention to data security

Benefits:

  • Competitive salary range (85k - 125k) with performance incentives
  • Remote work flexibility and supportive work culture
  • Professional development opportunities and career growth
  • Health insurance and retirement plan options
        `,
        descriptionLong: `
        
As a Database Administrator at FreeFer, your role will involve innovatively designing no-sql database structures and ensuring seamless integration with front-end frameworks. Your expertise in database design, administration, and security will be pivotal in supporting our dynamic web applications. 

Experience with the React-Redux framework will be advantageous in this role. If you are driven by challenges in data management and eager to contribute to impactful projects, we welcome your application.
        
    `,
        "payRate": "85k - 125k",
        "tags": ["85k - 125k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
      },
      // Scrum Master
      "Scrum-Master": {
        title: "Scrum Master",
        description: "Seeking an experienced scrum master or software development manager",
        
        hilights: `
Qualifications:

  • Proven experience as a Scrum Master or in a similar role in software development
  • Excellent skills in facilitating, scheduling, and conducting scrum ceremonies
  • Ability to support development teams in resolving logistical and technical blockers
  • Strong communication and leadership skills

Benefits:

  • Competitive salary (75k - 100k) with potential bonuses
  • Flexible and remote working options
  • A dynamic and supportive team environment
  • Comprehensive benefits package including health coverage
        `,
          descriptionLong: `
        
In the Scrum Master role at FreeFer, you will be a key facilitator in driving our software development projects towards success. Your primary responsibilities include scheduling and leading scrum ceremonies, such as daily stand-ups and backlog refinement, while providing continuous support to our development team. 

Your experience and leadership will be instrumental in maintaining a productive and positive work environment. If you are passionate about agile methodologies and team collaboration, we would be excited to have you on board.
        
        `,
            "payRate": "75k - 100k",
            "tags": ["75k - 100k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
          },
          // HR-Specialist
          "HR-Specialist": {
            title: "HR Specialist",
            description: "Seeking experienced HR specialists",
            
            hilights: `
Qualifications:

  • Experience in human resources management, payroll, and personnel administration
  • Knowledge of HR laws and regulations
  • Strong organizational skills and proficiency in HR software
  • Excellent communication and interpersonal skills

Benefits:

  • Competitive salary (65k - 85k) with annual bonuses
  • Flexible working hours and remote work options
  • Health benefits and professional development programs
  • A collaborative and inclusive workplace culture
        `,
            descriptionLong: `
        
As an HR Specialist at FreeFer, you will be responsible for managing various HR tasks including payroll, scheduling, personnel management, and staying up-to-date with applications. Your role is integral in ensuring smooth and efficient HR operations within our company. 

We are looking for individuals with solid experience in HR or relevant education to join our team. Your contribution will be key to fostering a healthy and productive work environment.
        
        `,
            "payRate": "65k - 85k",
            "tags": ["65k - 85k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
          },
          // AI-Integration-Specialist
          "AI-Integration-Specialist": {
            title: "AI Integration Specialist",
            description: "Seeking an AI integration specialist",
            
            hilights: `
Qualifications:

  • Experience with TensorFlow and AI model creation
  • Knowledge in utilizing existing AI models in web development
  • General web development skills including front-end and back-end technologies
  • Passion for AI technologies and innovation

Benefits:

  • Top-tier salary range (125k - 150k) with performance bonuses
  • Flexible work arrangements and remote work options
  • Access to cutting-edge technology and resources
  • Health benefits and a supportive team environment
        `,
            "descriptionLong": `
        
Joining FreeFer as an AI Integration Specialist means you will be engaging with groundbreaking AI technologies. Your role involves creating and implementing AI models into various web frameworks, contributing to innovative solutions. 

Your experience with TensorFlow, model development, and overall web development expertise will be crucial. If AI innovation excites you and you're ready to make significant contributions, we encourage you to apply.
        
        `,
            "payRate": "125k - 150k",
            "tags": ["125k - 150k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
          },
            "Customer-Service-Representative": {
              "title": "Customer Service Representative",
              "description": "Looking for customer-oriented representatives.",
              "hilights": `
      Qualifications:
      
        • Understanding and patient
        • Computer Literacy
        • Ability to communicate effectively 
        • Upbeat attitude  
      
      Benefits:
      
        • Competitive salary (45k - 55k)
        • Remote work available
        • Health Plans
              `,
              "descriptionLong": `
      Your task will be to assist customers with any questions or technical needs they may have. It's mostly just simple questions and scheduling, and you will be provided on-going training.
              `,
              "payRate": "45k - 55k",
              "tags": ["45k - 55k", "Full Time", "Part Time", "Remote Available", "Customer Service"]
            },
        }
    },
  //   "CallCenterCo": {
  //     "name": "Real-Time Call Center Solutions",
  //     "description": "Call Center Co. specializes in providing exceptional customer service and support. Our team is dedicated to effective communication and problem-solving, ensuring customer satisfaction. We are currently expanding our team and looking for motivated individuals to join us in various roles, including call center representatives and office staff.",
  
  //     "reviews": [
  //       {
  //         "reviewer": "Anonymous User",
  //         "rating": 5,
  //       },
  //       {
  //         "reviewer": "Anonymous User",
  //         "rating": 4,
  //         "comment": "Great team environment and supportive management."
  //       },
  //       {
  //         "reviewer": "Anonymous User",
  //         "rating": 5,
  //         "comment": "Was promoted pretty fast and like the new role."
  //       },
  //       {
  //         "reviewer": "Anonymous User",
  //         "rating": 2,
  //         "comment": "Management could be improved."
  //       }
  //     ],
  
  //     "openPositions": {
  //       "Call-Center-Representative": {
  //         "title": "Call Center Representative",
  //         "description": "Looking for customer-oriented call center representatives.",
  //         "hilights": `
  // Qualifications:
  
  //   • Excellent communication skills and a friendly demeanor
  //   • Ability to handle customer inquiries and complaints effectively
  //   • Basic computer proficiency and ability to learn new software
  //   • High school diploma or equivalent; previous call center experience preferred
  
  // Benefits:
  
  //   • Competitive salary (30k - 40k) with performance bonuses
  //   • Flexible shifts and potential for remote work
  //   • Comprehensive training program and supportive team environment
  //   • Health benefits and employee wellness programs
  //         `,
  //         "descriptionLong": `
  // As a Call Center Representative, you will be the first point of contact for our customers, handling inquiries and providing solutions. You will receive comprehensive training to ensure you have all the necessary tools and knowledge to excel in this role. This position offers great opportunities for growth and development in customer service.
  //         `,
  //         "payRate": "30k - 40k",
  //         "tags": ["30k - 40k", "Full Time", "Remote Available", "Benefits", "Customer Service"]
  //       },
  //       "Office-Manager": {
  //         "title": "Office Manager",
  //         "description": "Seeking an experienced and organized Office Manager.",
  //         "hilights": `
  // Qualifications:
  
  //   • Proven experience in office management or administrative role
  //   • Strong organizational and planning skills
  //   • Proficiency in MS Office and office management software
  //   • Excellent communication and leadership skills
  
  // Benefits:
  
  //   • Competitive salary (50k - 60k) with bonuses
  //   • Opportunities for professional growth and development
  //   • Supportive and dynamic work environment
  //   • Health insurance and retirement benefits
  //         `,
  //         "descriptionLong": `
  // As an Office Manager, you will oversee daily administrative operations, coordinate with various departments, and ensure a smooth and efficient workplace. Your role is pivotal in maintaining an organized and positive office environment, contributing to the overall success of our team.
  //         `,
  //         "payRate": "50k - 60k",
  //         "tags": ["50k - 60k", "Full Time", "Benefits", "Experience Required", "Leadership"]
  //       },
  //       "HR-Coordinator": {
  //         "title": "HR Coordinator",
  //         "description": "Seeking a detail-oriented HR Coordinator.",
  //         "hilights": `
  // Qualifications:
  
  //   • Experience in HR or related field
  //   • Knowledge of HR policies and procedures
  //   • Strong interpersonal and communication skills
  //   • Proficiency in HR software and tools
  
  // Benefits:
  
  //   • Competitive salary (45k - 55k) with potential for bonuses
  //   • Career advancement opportunities
  //   • Positive and inclusive work culture
  //   • Comprehensive benefits package
  //         `,
  //         "descriptionLong": `
  // As an HR Coordinator, you will assist in various HR functions including recruitment, onboarding, and employee relations. Your role is crucial in supporting our team and ensuring a healthy workplace culture. This position is perfect for those looking to build a career in human resources.
  //         `,
  //         "payRate": "45k - 55k",
  //         "tags": ["45k - 55k", "Full Time", "Benefits", "HR Experience", "Team Support"]
  //       },
  //       "Technical-Support-Specialist": {
  //         "title": "Technical Support Specialist",
  //         "description": "Seeking knowledgeable Technical Support Specialists for our call center.",
  //         "hilights": `
  //       Qualifications:
        
  //         • Experience in technical support or IT helpdesk roles
  //         • Strong understanding of computer systems and common software applications
  //         • Excellent problem-solving and communication skills
  //         • Ability to provide step-by-step technical help, both written and verbal
        
  //       Benefits:
        
  //         • Competitive salary (35k - 45k) with performance incentives
  //         • Opportunities for career growth and skills development
  //         • Flexible work hours and remote work options
  //         • Health insurance and employee assistance programs
  //         `,
  //         "descriptionLong": `
  //       As a Technical Support Specialist, you will be responsible for providing technical assistance and support to customers facing hardware or software issues. This role involves diagnosing and resolving technical issues, guiding customers through troubleshooting steps, and ensuring a high level of customer satisfaction. This position is ideal for those with a passion for technology and customer service.
  //         `,
  //         "payRate": "35k - 45k",
  //         "tags": ["35k - 45k", "Full Time", "Remote Available", "Technical Support", "IT Skills"]
  //       },        
  //     }
  //   }   
}