import { ref, set, update } from '@firebase/database';
import React, { useRef, useState } from 'react'
import { db } from '../../GlobalState/AppSlice';
import FileUploader from '../FileUploader/FileUploader';
import "./DBInput.css"
import editIcon from "../../Media/editIconGold.png"
import likeIcon from "../../Media/likeIconGold.png"
/*


*/
function DBInput({savePath, fileStoragePath, defaultValue, type, label, fullWidth, viewMode, options}) {

    // After this amount of inactivity after an input the input will save
    let timeout = "500ms"
    let inputTimeout = useRef()

    const [viewModeLocal, setViewModeLocal] = useState(viewMode)

    function inputUpdated(value){
        // Clear the timeout so it only saves once after the timeout interval
        clearTimeout(inputTimeout.current)

        // After the timeout interval save the value to the database
        inputTimeout.current = setTimeout(() => {
            set(ref(db, savePath), value)
        }, timeout);
    }

    function inputField(){
        // textarea
        if(type === "textarea")
            return(
                <>
                    <textarea className='dbInputTextArea' onChange={(e)=>inputUpdated(e.target.value)} defaultValue={defaultValue}></textarea>
                </>
            ) 
        // select
        else if(type === "select")
            return(
                <>
                    {/* {"options: "+options} */}
                    <select onChange={(e)=>inputUpdated(e.target.value)} className='dbInputInput' defaultValue={defaultValue}>
                        {options?.map(option => (
                            <option selected={option === defaultValue}>{option}</option>
                        ))}
                    </select>
                </>
            ) 
        // file upload
        else if(type === "file")
            return (
            <>
                <FileUploader
                    // The default value will be the file object
                    fileStoragePath={fileStoragePath}
                    databasePath={savePath}
                    fileDataObject={defaultValue}
                    fileIdentifier={label}
                ></FileUploader>
            </>
            )
        // input field (default)
        else{
            if(viewMode)
                return (
                    <>
                        {viewModeLocal?
                            <div className='dbInputReview'>{defaultValue}</div>
                            :
                            <input className='dbInputReview' onChange={(e)=>inputUpdated(e.target.value)} defaultValue={defaultValue}></input>
                        }
                        <div className='dbInputReviewEdit' onClick={()=>setViewModeLocal(!viewModeLocal)} title={viewModeLocal?"Edit":"Save"}>
                            <img src={viewModeLocal?editIcon:likeIcon}></img>
                        </div>
                    </>
                )
            else
                return (
                    <>
                        <input className='dbInputInput' onChange={(e)=>inputUpdated(e.target.value)} defaultValue={defaultValue}></input>
                    </>
                )
        } 

    }

  return (
    <div className={'dbInput '+(fullWidth ? " dbInputFullWidth":"")}>
        <div className='dbInputLabel'>{label}</div>
        {inputField()}
    </div>
  )
}

export default DBInput