import React from 'react'

function DScreening() {
  return (
    <div className='dScreeningContainer'>
        <div className='dScreening'>
            DScreening
        </div>

    </div>
  )
}

export default DScreening