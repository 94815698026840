import React, { useEffect, useId, useRef, useState, useSyncExternalStore } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import ChatBar from './ChatBar'
import { openFloatingChat, pushUserAction, setChatSelectorChatUserID, setChatUserID, setShowChat, setShowChatsWindow, setShowTopNavPlaceholder, toggleshowChat, toggleShowFloatingNewChatWindow } from '../../GlobalState/AppSlice'
// import NewChatUserSelector from './NewChatUserSelector'
import "./Chats.css"
import "./ChatsOld.css"
import ChatBars from './ChatBars'
import NewChatWindow from './NewChatWindow'
import Chat from './Chat'
import { addToActivityLog } from '../../Functions'

/*
  This is the window that comes up when the user clicks the chats icon
  It contains: 
    the list of the user's chats
    the active chat if one of the chats is selected
    options bar (create new chat, close window)

  There are functions here related to the opening and closing animation

*/
function ChatWindow2({inAdminScreen, userApplicationState}) {
  
  // Determines which screen/chat is shown
  const userID = useSelector(state => state.appSlice.userID)
  const chatUserID = useSelector(state => state.appSlice.chatUserID)
  const showNewChatSelector = useSelector(state => state.appSlice.showNewChatSelector)
  const dispatcher = useDispatch()
  
  // Related to the animation
  const showChat = useSelector(state => state.appSlice.showChat)
  const [showWidow, setShowWidow] = useState()

  // ========================================
  // #region adjust for mobile keyboard height
  const [height, setHeight] = useState()
  const [viewportHeight, setViewportHeight] = useState()
  
  useEffect(()=>{
    resizeListener()
  },[])

  // Want to set the height of the chat window so the top is always showing
  function resizeListener(){
    window.addEventListener("resize", getHeights)
  }
  function getHeights(event){
    // console.log(window.innerHeight)
    setHeight(window.innerHeight)
    // console.log(window.visualViewport.height)
    setViewportHeight(window.visualViewport.height)
  }
  // #endregion adjust for mobile keyboard height

  // ========================================
  // #region Open Close Anmiations
  const [zeroHeight, setZeroHeight] = useState(true)
  useEffect(()=>{
    if(inAdminScreen){
      if(userApplicationState?.chatWindowOpen)
        openWindow()
      else
        closeWindow()
    }
    else{
      if(showChat)
        openWindow()
      else
        closeWindow()
    }

  },[showChat, userApplicationState])

  const openTimeiout = useRef()
  function openWindow(){

    clearTimeout(openTimeiout.current)
    clearTimeout(closeTimeout.current)

    // setCreateNewChatMode(false)

    setShowWidow(true)
    // dispatcher(setShowTopNavPlaceholder(true))

    openTimeiout.current = setTimeout(() => {
      setZeroHeight(false)
    }, 50);

  }

  const closeTimeout = useRef()
  function closeWindow(){

    clearTimeout(openTimeiout.current)
    clearTimeout(closeTimeout.current)

    // Dispatch an action for admin data showing they closed the window
    // dispatcher(pushUserAction({type:"closeWindow", window: "chat"}))

    setZeroHeight(true)
    closeTimeout.current = setTimeout(() => {
      setShowWidow(false)
      // dispatcher(setShowTopNavPlaceholder(false))
    }, 1000);

  }
  // #endregion Open Close Anmiations

  // ========================================
  // #region Activity Log (open or close chat)

  const openedChat = useRef(false)
  useEffect(()=>{

    if(!userID) return

    // If the chat was previously open and now closing, show that the chat was closed 
    if(!showChat && openedChat.current){
      addToActivityLog("Closed Chat ", userID)
    }
    // If the chat is opened show that it has been opened and the chatUserID it was opened for
    if(showChat){
      addToActivityLog("Opened Chat: chatUserID: "+chatUserID, userID)
      openedChat.current = true
    }

  },[showChat, chatUserID, userID])

  // #endregion Activity Log

  return (
    <>
      {/* <div className='chatWindowOuter'>aa</div> */}
      {showWidow &&
        <div className='chatWindowOuter' style={{height: "calc(100% - "+(height - viewportHeight)+"px)"}}>
            <div className={'chatWindow ' + (zeroHeight ? ' chatWindowMinimized':"")} > 
                <>
                  {chatUserID?  
                    <Chat></Chat>
                    :
                    <>
                      {showNewChatSelector?
                        <NewChatWindow></NewChatWindow>
                        :
                        <ChatBars></ChatBars>
                      }
                    </>
                  }
                </>
            </div>
        </div>  
      }
    </>
  )
}

export default ChatWindow2