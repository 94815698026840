import React from 'react'
import Navbar from '../NavBar/Navbar'
import CompanyPositions from './CompanyPositions'

function PositionsPage() {
  return (
    <div>
        <Navbar top color={"#D65E4D"}></Navbar>
        <div className='appScroller padForNavbar'>
            <CompanyPositions></CompanyPositions>
        </div>
    </div>
  )
}

export default PositionsPage