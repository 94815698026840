import React from 'react'
import PageContiner from './PageContiner'
import gearIcon from "../../Media/settingsIconS.png"
import Menu from '../Menu/Menu'

function MaintancePage() {
  return (
    <>
        <PageContiner>
        <div className='underMaintance'>
            <div className='underMaintanceImg'>
                <img src={gearIcon}></img>
            </div>
            <div>Page Is Currently Under Maintance</div>
            <Menu></Menu>
        </div> 
        </PageContiner>   
    </>
  )
}

export default MaintancePage