import React from 'react'
import Menu from './Menu'
import "./Windows.css"
import { useDispatch, useSelector } from 'react-redux'
import { setShowMenu } from '../../GlobalState/AppSlice'

function MenuWindow() {

  const showMenu = useSelector(state => state.appSlice.showMenu)
  const dispatcher = useDispatch()

  return (
    <>
      {showMenu ? 
        <div className='menuWindow'>
            <div>
              <div className='menuTitle'>
                Menu
              </div>
              <div className='closeButton' onClick={()=>dispatcher(setShowMenu(false))}>x</div>
            </div>
            <Menu></Menu>
        </div>
        :
        <></>
      }
    </>
  )
}

export default MenuWindow