import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import UserSearchResult from './UserSearchResult'
import { isValidObject } from '../../Functions'

function NewChatUsers({nameFilter, openChatWithUser}) {

    const searchableProfiles = useSelector(state => state.appSlice.searchableProfiles)

    const [filteredUsers, setFilteredUsers] = useState([])

    useEffect(()=>{
        filterUsers()
    },[nameFilter, searchableProfiles])

    function filterUsers(){
        if(typeof searchableProfiles !== "object") return

        if(!nameFilter) nameFilter = ""

        let tempFilteredUsers = []
        let searchName = nameFilter.toLowerCase()
        
        // ensure a valid all users object
        if(!isValidObject(searchableProfiles)) return

        // Object.entries(filterUsersToDonors(allUsers)).forEach(([userID, userData]) => {
        Object.entries(searchableProfiles).forEach(([userID, profileData]) => {
            let userName = profileData?.name?.toLowerCase()
            if(typeof userName === "string" && userName.includes(searchName)){
                let tempUserDataObject = {id: userID, ...profileData}
                tempFilteredUsers.push(tempUserDataObject)
            }
        })
        console.log("tempFilteredUsers:")
        console.log(tempFilteredUsers)

        setFilteredUsers(tempFilteredUsers)
    }

  return (
    <div className='newChatUsers'>
        {filteredUsers.map(profileData => (
            <div key={profileData.id}>
                <UserSearchResult profileData={profileData} openChatWithUser={openChatWithUser}></UserSearchResult>
            </div>
        ))}
    </div>
  )
}

export default NewChatUsers