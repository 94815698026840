import React, { useEffect, useState } from 'react'
import ApplyPage2 from '../Careers/ApplyPage2'
import { Link, useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { scrollTop } from '../../Functions'
import ApplyBox2 from '../Careers/ApplyBox2'

function PositionDisplay({positionDataParam, startingTab}) {

    const [selectedTab, setSelectedTab] = useState(startingTab || "Overview")
    const tabOptions = ["Overview", "Apply"]
    
    // Put the data in state when the param changes so the display updates 
    const [positionData, setPositionData] = useState()
    useEffect(()=>{
        setPositionData(positionDataParam)
    },[positionDataParam])
    
    useEffect(()=>{
        scrollTop()
    },[selectedTab])


  return (
    <div className={'positionDisplay '+(isMobile?" positionDisplayMobile":"")}>
        {positionData ? 
            <>
                <div>
                    <div className='title positionTitle'>{positionData?.title}</div>
                    <div className='flexCenter'>
                        <div className='flexRow copmanyOverviewButtons width80'>
                            {tabOptions.map(optionName => (
                                <div className={'sectionButton '+(selectedTab === optionName ? " sectionButtonSelected":"")} onClick={()=>setSelectedTab(optionName)}>{optionName}</div>
                            ))}
                        </div>
                    </div>
                    
                    {selectedTab === "Apply" ?
                    
                        <div className='applicationBoxContainer'>
                            <div className='applicationBox'>
                                {/* <ApplyPage2 applicationData={positionData}></ApplyPage2> */}
                                <ApplyBox2 applicationName={"FreeFer Career "+positionData.title} title={positionData.title}></ApplyBox2>
                            </div>
                        </div>
                        :
                        <> 
                            {/* <div>
                                {"Company: "} 
                                <Link to={"/"+positionData.companyID} target='_blank'>
                                    <div className='inlineBlock'>
                                        <span className='hoverUnderline'>
                                            {positionData?.company}
                                        </span>
                                    </div>
                                </Link>
                            </div> */}
                            <div>
                                {"Pay Range: "} 
                                {positionData?.payRate}
                            </div>
                            <hr></hr>

                            <div className='positionTags'>
                                {positionData?.tags?.map(tag=>(
                                    <div className='positionTag'>{tag}</div>
                                ))}
                            </div>
                            <hr></hr>

                            {/* <div style={{width: "70%"}} className='inlineBlock'>  */}
                            <div className='inlineBlock'> 
                                <div className='positionTitle positionSubTitle'>Hilights:</div>
                                <pre>
                                    {positionData?.hilights}
                                </pre>
                            </div>
                            <hr></hr>
                            <div className='inlineBlock'>
                                <div className='positionTitle positionSubTitle'>Job Description:</div>
                                <pre>
                                    {positionData?.descriptionLong}
                                </pre>
                            </div>
                            <hr></hr>
                            <button onClick={()=>setSelectedTab("Apply")}>Apply Here</button>
                            <hr></hr>
                        </>
                    }



                    {/* <Link to={"/"+companyName+"/"+positionData?.id} target='_blank'>
                        <button>Open In New Tab</button>
                    </Link> */}
                </div>

            </>
            :
            <div className='pageHeight'>
                <div className='flexCenter'>
                    <div className='title'>Select A Position To View Details</div>
                </div>
            </div>
        }
    </div>
  )
}

export default PositionDisplay