import { deleteUser, getAuth } from "@firebase/auth";
import { getDatabase, push, ref, set, update } from "@firebase/database";
import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";
import { createSlice } from "@reduxjs/toolkit";
import { addProfileDataToUserData, dateString, deleteImage, isValidObject, objectKeyToArray, pushNotification } from "../Functions";
// import * as admin from "firebase-admin"

    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyBNEXnKlK5cBhiP_XHkKHHCkE3bI06Mmtk",
        authDomain: "test-project-5dcc3.firebaseapp.com",
        databaseURL: "https://test-project-5dcc3-default-rtdb.firebaseio.com",
        projectId: "test-project-5dcc3",
        storageBucket: "test-project-5dcc3.appspot.com",
        messagingSenderId: "486884697012",
        appId: "1:486884697012:web:7728c0433d6d2aae47c8e1"
    };
  
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Connect to the necessary services
    export const auth = getAuth(app)
    export const db = getDatabase(app)
    export const storage = getStorage(app)
  
    const appSlice = createSlice({
        name: "appSlice",
        initialState: {
            // Search
            showFilters: false,
            filterObject: {},
            viewProfileID: null,
            // Chat
            showChat: false,
            chatUserID: null,
            showNewChatSelector: false,
            // Windows
            showMenu: false,
            imageArrayViewerArray: null,
            imageArrayViewerIndex: 0,
            deleteImageObject: null,
            // Window Type
            isMobile: true,
            // Data
            profileData: null,
            chats: null,
            allUsers: null,
            // Auth
            userID: null,
            oldUserID: null,
            isAnon: false,
            interacted: false,
            // Admin
            adminData: {},
            syncAlternator: false,
            newUserID: null,
            adminEditUserID: null,
        },
        reducers: { 
            // General 
            setIsMobile: (state, action) => {
                state.isMobile = action.payload
            },
            // Search
            setSearchableProfiles: (state, action) => {
                state.searchableProfiles = action.payload
            },
            setShowFilters: (state, action) => {
                state.showFilters = action.payload
            },
            toggleShowFilters: (state, action) => {
                state.showFilters = !state.showFilters 
            },
            setFilterObject: (state, action) => {
                state.filterObject = action.payload
            },
            setViewProfileID: (state, action) => {
                state.viewProfileID = action.payload
            },
            
            // Chat
            setShowChat: (state, action) => {
                state.showChat = action.payload
                state.showNewChatSelector = false
            },
            toggleShowChat: (state, action) => {
                state.showChat = !state.showChat
            },
            setShowNewChatSelector: (state, action) => {
                state.showNewChatSelector = action.payload
            },
            // For when the user select an existing chat to view it
            setSelectedChatID: (state, action) => {
                state.selectedChatID = action.payload
            },
            // For when the user is creating a new chat
            setNewChatUserID: (state, action) => {
                state.newChatUserID = action.payload
            },
            backToChats: (state, action) => {
                state.selectedChatID = null
                state.newChatUserID = null
            },
            setChatUserID: (state, action) => {
                state.chatUserID = action.payload
                state.showNewChatSelector = false
            },
            // action.payload is chatDataObject
            setAsRead: (state, action)=>{
                
                let chatDataObject = action?.payload
                if(!chatDataObject || typeof chatDataObject !== "object") return

                // If the new message was sent by this user don't mark it as read
                if(chatDataObject?.chatPreviewData?.mostRecentMessage?.from === state.userID) return

                // Check each user type so it knows where to update the data
                let thisUserDonor = state.profileData?.userType
                let otherUserDonor = state.allUsers[chatDataObject?.otherUserID]?.profileData?.userType || false

                // Update the read state for this user
                if(thisUserDonor)
                    update(ref(db, "freeFer/allUsers/donors/"+state.userID+"/chats/"+chatDataObject?.chatID+"/mostRecentMessage"), {read: true})
                else
                    update(ref(db, "freeFer/allUsers/users/"+state.userID+"/chats/"+chatDataObject?.chatID+"/mostRecentMessage"), {read: true})
                
                // Update the read state for the other user
                if(otherUserDonor)
                    update(ref(db, "freeFer/allUsers/donors/"+chatDataObject?.otherUserID+"/chats/"+chatDataObject?.chatID+"/mostRecentMessage"),  {read: true})
                else
                    update(ref(db, "freeFer/allUsers/users/"+chatDataObject?.otherUserID+"/chats/"+chatDataObject?.chatID+"/mostRecentMessage"),  {read: true})
                
                // Update the most recent message for both to indicate that it has been read
                // update(ref(db, "freeFer/users/"+state.userID+"/chats/"+chatDataObject?.chatID+"/mostRecentMessage"), {read: true})
                // update(ref(db, "freeFer/users/"+chatDataObject?.otherUserID+"/chats/"+chatDataObject?.chatID+"/mostRecentMessage"),  {read: true})

            },

            // Windows
            setShowMenu: (state, action) => {
                state.showMenu = action.payload
            },
            toggleShowMenu: (state, action) => {
                state.showMenu = !state.showMenu
            },
            /**
             * action.payload: {imageArray: [...imageDataObjects], index: integer}
             */
            openImageArrayViewer: (state, action) =>{
                state.imageArrayViewerArray = action.payload.imageArray
                state.imageArrayViewerIndex = action.payload.index || 0
            },
            setImageArrayViewerIndex: (state, action) =>{
                state.imageArrayViewerIndex = action.payload || 0
            },
            closeImageArrayViewer: (state, action) =>{
                state.imageArrayViewerArray = null
                state.imageArrayViewerIndex = 0
            },
            setDeleteImageObject: (state, action) => {
                state.deleteImageObject = action.payload
            },

            // Admin
            syncAppState: (state, action) => {
                state.syncAlternator = !state.syncAlternator
            },
            toggleSyncAlternator: (state, action) =>{
                state.syncAlternator = !state.syncAlternator
            },
            setAdminData: (state, action) => {
                state.adminData = action.payload
            },
            setSessionID: (state, action) => {
                state.sessionID = action.payload
            },
            setAdminEditUserID: (state, action) => {
                state.adminEditUserID = action.payload
            },
            /** payload is userID and userType */
            deleteUserAction: (state, action) => {
                if(!action?.payload?.userID){
                  // console.log("deleteUserAction takes {userID: string, userType: string")
                    return
                }

                /*
                    let newImagePathObject = {
                        downloadURL: url, 
                        storageURL: storageURL.current,
                        dataPath: dataPath, 
                        name: file?.name,
                        uploadDate: dateString()
                    }
                */

                // Get the userData for the user that is being deleted
                let userData = state.allUsers[action?.payload?.userID]

                // Delete all images
                let profileData = userData?.profileData
              // console.log("deleting user with name: "+profileData?.name)
              // console.log(profileData)
                if(isValidObject(profileData?.images)){
                    Object.entries(profileData?.images).forEach(([imageObjectID, imageObject]) => {
                        deleteImage(imageObject)
                    })
                }

                // firebase-admin seems to only work from server side so can't do this here
                // Remove auth account if there is one. Requires recent login
                // try{
                //     const user = auth.currentUser
                //     deleteUser(user)
                // }catch{

                // }
                
                // Delete profile data
                if(action.payload.userType === "donor")
                    set(ref(db, "freeFer/allUsers/donors/"+action.payload.userID), null)
                else
                    set(ref(db, "freeFer/allUsers/users/"+action.payload.userID), null)
                 
            },
            // Depreciated
            pushUserAction: (state, action) => {
                if(!state.sessionID){
                    // console.log("pushUserAction missing session ID")
                    return
                }
                if(!state.userID){
                  // console.log("pushUserAction missing user ID")
                    return    
                }
                // This should include the type and other data. ex: {type: "openPage", page: "search"} or {type: "viewUser", otherUserID: "otherUserID"}
                let actionData = action.payload
                // Add a date and time to the action
                actionData.dateTile = dateString()

                // Create a location in the database to hold the data for the new action
                let newActionRef = push(ref(db, "freeFer/admin/users/"+state.userID+"/sessions/"+state.sessionID+"/actions"))
                // Put the action data in the database
                set(newActionRef, actionData)

            },
            setInteracted: (state, action) => {
                state.interacted = action.payload
            },
            // THe mirror of the app state for admin view
            updateApplicationState: (state, action) => {
                
                /*
                    Update the state of this users application so it can be viewed in the admin data
                
                    this can be updated when the user performs an action that would change one of thes
                    it can be called from useEffect and all of this state variables that need to be updated can be put in the dependency array so very little code will need to be added
                    it can capture the current value of all of these in a ref every time its called so the next time its called it can compare and add actions accordingly
                    
                    what page is open
                    if the chat window is open 
                    if there is a user selected in chat
                    if the menu is open
                    if they are viewing a profile from the search page
                    if they are viewing their own profile
                    The furrenc page
                    isMobile state
                    what theyre typing
                    search filters
                    time stap on each new state

                    This can be used as just a text view and also as a visual display

                    if they are active, in unmount listener if there are no open sessions aside from the one it is removing set active to false here too (maybe not necessary but convienient)

                */

                if(!state.userID){
                    // console.log("no user id in update application state")
                    return
                }

                if(!action.payload || typeof action.payload !== "object") return

                // Creates a new entry each time
                // let newActionRef = push(ref(db, "freeFer/admin/users/"+state.userID+"/currentApplicationState/history"))
                // update(newActionRef, action.payload)

                // Updates the db to reflect the current state of the user's application
                update(ref(db, "freeFer/admin/users/"+state.userID+"/currentApplicationState"), action.payload)

            },
            
            // Data
            setProfileData: (state, action) => {
                state.profileData = action.payload
            },
            setChats: (state, action) => {
                state.chats = action.payload
            },
            setAllUsers: (state, action) => {
                state.allUsers = action.payload

                // let tempAllUsers = {...state.allUsers, ...action.payload}
                // state.allUsers = tempAllUsers
            },
            // Update or add user data to the database based on given userID and userData
            updateAllUsers: (state, action) => {
                let tempAllUsers = state.allUsers
                let userToUpdateID = action.payload.userID
                let userData = action.payload.userData
                if(!userToUpdateID) { console.log("no userID in updateAllUsers"); return;}
                if(!userData) { console.log("no userData in updateAllUsers"); return;}

                tempAllUsers[userToUpdateID] = userData
                
                state.allUsers = tempAllUsers

            },
            // payload contains an object with all of the users {userID: userData, userID: userData, ...}
            // This works but now refresh is needed to remove deleted users from stat.allUsrs
            updateAllUsers2: (state, action) => {
                
                let newAllUsers = action.payload                
                if(!isValidObject(newAllUsers)) { console.log("updateAllUsers2 newAllUsers not valid"); return;}
                
                // Get or create the allUsers object
                let tempAllUsers = state.allUsers
                if(!isValidObject(tempAllUsers)) 
                    tempAllUsers = {}

                Object.entries(newAllUsers).forEach(([userID, userData]) => {
                    tempAllUsers[userID] = userData
                })
                
                state.allUsers = tempAllUsers
            },
            // Takes {id: userID, userData: {...userData}}
            addUserToAllUsers: (state, action) => {
                if(!action.payload.userID || !action.payload.userData || typeof action.payload.userData !== "object"){
                  // console.log("addUserToAllUsers missing data")
                    return
                }
                let tempAllUsers = {...state.allUsers}
                tempAllUsers[action.payload.userID] = action.payload.userData
                // console.log("tempAllUsers:")
                // console.log(tempAllUsers)
                state.allUsers = tempAllUsers
            },
            setUserData: (state, action) => {
                state.userData = action.payload
            },
            updateScroll: (state, action) => {
                // Takes a scroll position and a name
                // Looks in the scrollUpdateTimeObject to see if the scroll position associated with that name has been updated in the last second
                // If it has not it will update the scroll position object in the admin data for the user 
                
                // Put the datetime of the last update for the scroll position of that name
                //state.scrollUpdateTimeObject

                // This is called in useEffect and on scroll in pages where scroll positoin is tracked

                // Get the time in ms since epoch
                let date = new Date()
                let ms = date.getTime()
                
                // If the scroll position has not been set or was set over 2 seconds ago set it again 
                let scrollPosition = action.payload.scrollPosition
                if(!state.scrollUpdateMS || state.scrollUpdateMS < ms - 2000){
                  // console.log("updating scroll position")
                    state.scrollPotsition = scrollPosition
                    // Save the time in ms of this update so next time its called it can be compared to
                    state.scrollUpdateMS = date.getTime() 
                }

                // what if they scroll a lot in less than 2 seconds and it doesn't update. maybe should user a timer in a component

                // update initial posiiton
                // on each scroll check if it has been 2 seconds, if not don't update
                // also set a timeout so it will call this again after 2 seconds. Can this be done in an action?


            },

            // Auth
            setUserID: (state, action) => {
                state.userID = action.payload
            },
            setOldUserID: (state, action) => {
                state.oldUserID = action.payload
            },
            setIsAnon: (state, action) => {
                state.isAnon = action.payload
            },
        

            /**
             * payload is userData with and .ID attribute
             */
            updateUserDataByID: (state, action) => {

                if(!state.profileData) return
        
                // Make sure there is a valid user ID to put the updated user data in
                if(typeof action.payload !== "object") return

                // Use the user ID in the object or the userID
                let userID = action.payload.ID

                if(!userID){
                    if(!state.userID) return
                    let userData = {...action?.payload}
                    if(state?.profileData?.userType === "donor")
                        update(ref(db, "freeFer/allUsers/donors/" + state?.userID), userData)
                    else
                        update(ref(db, "freeFer/allUsers/users/" + state?.userID), userData)
                }
                else{
                    // Create a local userData object and remove the ID if there is one
                    let userData = {...action.payload}
                    if(userData?.ID)
                        delete userData.ID

                    // Update the db based on the user type
                    if(action.payload.profileData?.userType === "donor")
                        update(ref(db, "freeFer/allUsers/donors/" + userID), userData)
                    
                    else
                        update(ref(db, "freeFer/allUsers/users/" + userID), userData)   
                }

            },
            updateProfileData: (state, action) => {
                // Make sure there is a valid user ID to put the updated user data in
                if(!state.userID || typeof action.payload !== "object") return

                let profileData = {...action.payload}
                if(profileData?.ID)
                    delete profileData.ID

              // console.log("====================")
              // console.log("updateProfileData "+state.profileData?.userType)
                if(!profileData) return

              // console.log("updateProfileData "+state.profileData?.userType)

                if(state.profileData?.userType === "donor")
                    update(ref(db, "freeFer/allUsers/donors/" + state.userID + "/profileData"), profileData)
                else
                    update(ref(db, "freeFer/allUsers/users/" + state.userID + "/profileData"), profileData)

            },
            updateProfileDataByID: (state, action) => {

                // Create a user object with no ID in it
                let newProfileData = {...action.payload}
                // Get the user ID from the payload else use the userID in state
                let userID = action.payload.ID || state.userID
                // Remove the ID attribute becaues it is just there to denote the user that should be modified and not to be saved in the db
                delete newProfileData.ID

                // Ensure thre is a valid userID
                if(!userID) return

                // Update the users profile data
                update(ref(db, "freeFer/users/userProfiles/"+userID), newProfileData)

            },
            updateProfileDataByIDDepreciated: (state, action) => {
                

                // Create a user object with no ID in it
                let newProfileData = {...action.payload}
                // Get the user ID from the payload else use the userID in state
                let userID = action.payload.ID || state.userID
                // Remove the ID attribute becaues it is just there to denote the user that should be modified and not to be saved in the db
                delete newProfileData.ID

                // Get the user data for the user that is being updated from allUsers with the userID
                let userToUpdateData = state.allUsers[userID]

                // If the newProfileData has userType === donor and the userToUpdateData profileData is not userType donor, move them into the donor portion of the db
                if(newProfileData?.userType === "donor" && userToUpdateData?.profileData?.userType !== "donor"){
                    
                    // Create a userData object with the new profile data in it
                    let newUserData = addProfileDataToUserData(newProfileData, userToUpdateData)

                    // Database transfer by adding to one and removing from the other
                    set(ref(db, "freeFer/allUsers/users/"+userID), null)
                    set(ref(db, "freeFer/allUsers/donors/"+userID), newUserData)
                }
                // If the newProfileData does not have userType === donor and the userToUpdateData profileData is userType donor, move them into the user portion of the db
                else if(newProfileData?.userType !== "donor" && userToUpdateData?.profileData?.userType === "donor"){

                    // Create a userData object with the new profile data in it
                    let newUserData = addProfileDataToUserData(newProfileData, userToUpdateData)

                    // Database transfer by adding to one and removing from the other
                    set(ref(db, "freeFer/allUsers/users/"+userID), newUserData)
                    set(ref(db, "freeFer/allUsers/donors/"+userID), null)
                }
                // All other profile Data Changes
                else{
                    if(userToUpdateData?.profileData?.userType === "donor")
                        update(ref(db, "freeFer/allUsers/donors/" + userID + "/profileData"), newProfileData)
                    else
                        update(ref(db, "freeFer/allUsers/users/" + userID + "/profileData"), newProfileData)
                }


            },
            // Creates an anonymous user
            createUser: (state, action) => {
                let userDateObject = {}
                if(!action?.payload) 
                    userDateObject = action?.payload

                // Make a new reference in the database
                let newRef = push(ref(db, "/freeFer/allUsers/users"))

                // Put the user object in the database in newKey/profileData
                set(ref(db, "/freeFer/allUsers/users/"+newRef.key+"/profileData"), action.payload)
                
                // Set this state variable so 
                state.newUserID = newRef.key
                
                if(state.adminEditUserID === "newUser")
                    state.adminEditUserID = newRef.key

            },   
            // Loads or creates an anon user
            loadAnonUser: (state, action) => {
                // Look in local storage for an anonymous user ID
                let anonUserID = window.localStorage.getItem("anonUserID")
                // If there is one set it as the userID
                if(anonUserID){

                  // console.log("loading existing anon user: "+anonUserID)
                    state.userID = anonUserID
                    let notificationData = {profileID: anonUserID, type: "User Login Anon"}
                    pushNotification(notificationData)
                }
                // If not create one and save it
                else{
                  // console.log("creating anon user ")

                    // console.log("creating a new anon user")
                    // Make a new reference in the database (comes with an uniqueID)
                    let newRef = push(ref(db, "/freeFer/allUsers/users"))

                    // Put the user object in the database in
                    set(ref(db, "/freeFer/allUsers/users/"+newRef.key+"/profileData"), {
                        name: "anonymous",
                    })

                    // Save it in local storage for next time
                    window.localStorage.setItem("anonUserID", newRef.key)

                  // console.log("Created anon user: " + newRef.key)

                    state.userID = newRef.key

                    let notificationData = {profileID: newRef.key, type: "New User Anon"}
                    pushNotification(notificationData)

                }
                // Sets the anon flag so the create account section shows
                state.isAnon = true
                // console.log("is anon true")
            },
            // Dev function that moves all users from /users into /allUsers/<userType>
            moveUsers: (state, action) => {
                // console.log("move users")
                // console.log("state.allUsers")
                // console.log(state.allUsers)
                // console.log(Object.entries(state.allUsers).length)
                if(!state.allUsers || typeof state.allUsers !== "object" || Object.entries(state.allUsers).length === 0) return
            
                let tempObject = {
                    donors: {},
                    users: {},
                }
                Object.entries(state.allUsers).forEach(([userID, userData]) => {
                    if(userData.userType === "donor")
                        tempObject.donors[userID] = userData
                    else
                        tempObject.users[userID] = userData
                })
                // console.log("tempObject: ")
                // console.log(tempObject)
                // return
                set(ref(db, "freeFer/allUsers"), tempObject)
            },

        }
    })

    // Reducer
    export const appSliceReducer = appSlice.reducer
    // General
    export const {setIsMobile} = appSlice.actions
    // Search
    export const {setSearchableProfiles, setShowFilters, toggleShowFilters, setFilterObject, setViewProfileID} = appSlice.actions
    // Chat
    export const {setSelectedChatID, setNewChatUserID, setShowNewChatSelector, backToChats, setShowChat, toggleShowChat, setChatUserID, setAsRead} = appSlice.actions
    // Windows
    export const {setShowMenu, toggleShowMenu, openImageArrayViewer, setImageArrayViewerIndex, closeImageArrayViewer, setDeleteImageObject} = appSlice.actions
    // Admin
    export const {syncAppState, setAdminData, setSessionID, setInteracted, updateApplicationState, toggleSyncAlternator, updateScroll, setAdminEditUserID, deleteUserAction} = appSlice.actions
    // Data
    export const {setProfileData, setChats, setAllUsers, updateAllUsers, updateAllUsers2, addUserToAllUsers, updateUserDataByID, updateProfileData, updateProfileDataByID, setUserData} = appSlice.actions
    // Auth
    export const {setUserID, setOldUserID, setIsAnon, loadAnonUser, createUser} = appSlice.actions
    export const {moveUsers} = appSlice.actions