import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { syncAppState } from '../../GlobalState/AppSlice'

function DirectoryCard({title, content, linkTo, linkText, userApplicationState}) {

    const isMobileGlobal = useSelector(state => state.appSlice.isMobile)
    const dispatcher = useDispatch()
    // Adjusting for admin screen view
    let isMobile
    if(userApplicationState)
        isMobile = userApplicationState.isMobile
    else
        isMobile = isMobileGlobal



  return (
    <div className={'contentBox '+(isMobile? "":"contentBoxDesktop")}>
        <div className='contentBoxTitle'>{title}</div>
        <p className='contentBoxText'>
            {content}
        </p> 
        <div>
            <Link to={linkTo} onClick={()=>dispatcher(syncAppState())}>
                <div className='contentBoxButton'>{linkText}</div>
            </Link>
            {/* <Link to={linkTo}>
                <div className='contentBoxButton'>{linkText}</div>
            </Link> */}
        </div>
    </div>
  )
}

export default DirectoryCard