import React, { useEffect, useState } from 'react'
import PositionDisplay from './PositionDisplay'
import { useParams } from 'react-router-dom'
import { companyData } from '../Careers/Data/Data'
import { getPositionData } from '../../Functions'
import Navbar from '../NavBar/Navbar'

function PositionDisplayPage() {
    const {companyID, positionID, tabName} = useParams()

    
    const [positionData, setPositionData] = useState()
    useEffect(()=>{
        console.log(" companyID: "+companyID+" positionID: "+positionID+" tabName: "+tabName)

        let tempPositionData = getPositionData(companyData, companyID, positionID)
        console.log(tempPositionData)

        // Modify this so the compnayID is not needed

        // let tempPositionData = companyData[companyID]?.openPositions[positionID]

        setPositionData(tempPositionData)
    
    }, [companyID, positionID])

    return (
    <div className='scroll100'>
        <Navbar top></Navbar>
        <PositionDisplay positionDataParam={positionData} startingTab={tabName}></PositionDisplay>
    </div>
  )
}

export default PositionDisplayPage