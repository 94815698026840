import React, { useEffect, useRef, useState } from 'react'
import NewChatUsers from './NewChatUsers'

function NewChatUserSelector({openChatFunction}) {

    const [nameFilter, setNameFilter] = useState()
    const nameFilterInputRef = useRef()

    useEffect(()=>{
        nameFilterInputRef.current.focus()
    },[])

    function nameFilterUpdated(){
        setNameFilter(nameFilterInputRef.current.value)
    }

  return (
    <>
        <div className='newChatToInputOuter'>
            To: <input className='newChatToInput' ref={nameFilterInputRef} onChange={nameFilterUpdated}></input>
        </div>
        <NewChatUsers nameFilter={nameFilter} openChatWithUser={openChatFunction}></NewChatUsers>
    </>
  )
}

export default NewChatUserSelector