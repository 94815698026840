import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAttributeFromInput, isValidObject } from '../../Functions'
import { createUser, updateProfileDataByID, updateUserDataByID } from '../../GlobalState/AppSlice'

function ProfileEditInputField({userIDOverride, attributeKey, attributeName, textarea, saveProfileDataCallback, profileData}) {

  // Global State
  const userID = useSelector(state => state.appSlice.userID)
  const usersProfileData = useSelector(state => state.appSlice.profileData)
  const dispatcher = useDispatch()

  // Saves the user data to their profile based on their user ID (or userIDOverride)
  const typeTimer = useRef()
  function autoSave(){
    // If there is an active timer clear it so it doesn't also run
    if(typeTimer.current)
      clearTimeout(typeTimer.current)

    typeTimer.current = setTimeout(() => {

      // Get the current user data object
      let updatedProfileData = userDataObject()

      // Add the userID to the input field so it knows which user to update
      updatedProfileData.ID = (userIDOverride || userID)
      
      // console.log("updatedProfileData")
      // console.log(updatedProfileData)

      saveProfileDataCallback({userID: userIDOverride, profileData: updatedProfileData})
      // If this is for a new user create a new user
      if(userIDOverride === "newUser")
        dispatcher(createUser(userDataObject()))
      // Else update the existing user
      else
        dispatcher(updateProfileDataByID(updatedProfileData))  
      
      typeTimer.current = null      
      
    }, 500);

  }

  // Creates a user data object based on the input values
  function userDataObject(){
    let newUserObject = {...profileData}

  // console.log("addAttributeFromInput(newUserObject: "+newUserObject+", attributeKey: "+ attributeKey+", "+ "input" + attributeKey + userIDOverride+")")
  // console.log(addAttributeFromInput(newUserObject, attributeKey, "input" + attributeKey + userIDOverride))
  // console.log("looking for input with ID: "+ "input" + attributeKey + userIDOverride)
    newUserObject = addAttributeFromInput(newUserObject, attributeKey, "input" + attributeKey + (userIDOverride || userID))
    return newUserObject
  }

  return (
    <div className='profileInputField' key={attributeKey+"ProfileDataEditBox"}>
        {textarea ? 
            <textarea onChange={autoSave} placeholder={attributeName} id={"input" + attributeKey + userIDOverride} defaultValue={isValidObject(profileData) && profileData?.[attributeKey]}></textarea>
            :
            <input onChange={autoSave} placeholder={attributeName} id={"input" + attributeKey + userIDOverride} defaultValue={isValidObject(profileData) ? profileData?.[attributeKey] : ""}></input>
        }
    </div> 
  )
}
ProfileEditInputField.defaultProps = {
  saveProfileDataCallback: (object => {console.log("Saving profile data for user: "+object?.userID+" : "); console.log(object?.profileData)})
}

export default ProfileEditInputField