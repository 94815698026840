import React from 'react'
import NewChatUserSelector from './NewChatUserSelector'
import { useDispatch } from 'react-redux'
import { setChatUserID, setShowChat, setShowNewChatSelector } from '../../GlobalState/AppSlice'
import backIcon from"../../Media/backIconBlue.png"
import minimizeIcon from"../../Media/minimizeIconBlue.png"

function NewChatWindow() {

    const dispatcher = useDispatch()

  return (
    <div>
        <div className=''>
            <div className='chatBarTitle'>
                New Chat
                <div className='imageButtonSize imageButtonPosition2' title="Open Chat in Floating Window" onClick={()=>dispatcher(setShowNewChatSelector())}>
                    <div className='imageButtonImage'>
                    <img src={backIcon}></img>
                    </div>
                    <div className='imageButtonLabel'>Back to Chats</div>
                </div> 
                <div className="imageButtonPosition imageButtonSize" title="Create New Chat" onClick={()=>dispatcher(setShowChat(false))}>
                    <div className='imageButtonImage2'>
                    <img src={minimizeIcon}></img>
                    </div>
                    <div className='imageButtonLabel'>Close</div>
                </div>             
            </div>
        
            {/* <div className='chatUserInfo' onClick={()=>dispatcher(setViewProfileID(chatOtherUserID))}>
                <Link to={"/profile/"+chatOtherUserID}>
                <div className='chatUserImage'>
                    <img src={otherUserProfileData?.images?.main?.downloadURL}></img>
                </div>
                </Link> 
                <div className='chatUserName'>{otherUserProfileData?.name || "Select A Chat"}</div>
            </div> */}
        </div>
        <NewChatUserSelector></NewChatUserSelector>

    </div>
  )
}

export default NewChatWindow