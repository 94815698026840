import React, { useEffect, useRef, useState } from 'react'
import sendIcon from "../../Media/sendIconBlue.png"
// import Message from './Message'
import EmojisWindow from './EmojisWindow'
import smileIcon from "../../Media/smileIconBlue.png"
import { updateApplicationState } from '../../GlobalState/AppSlice'
import { useDispatch, useSelector } from 'react-redux'
import { readableJson } from '../../Functions'
import Message from './Message'
import IceBreaker from './IceBreaker'

function ChatMessages({inFloatingWindow, sendMessageDB, messages, chatData, otherUserProfileData, loadMoreMessages}) {

    const dispatcher = useDispatch()

    // #region DB interaction
    const inputRef = useRef()

    // ================================================================================
    // #region Key press listener setup

    useEffect(()=>{
      // TODO: Remove this listener when the component is destroyed (if this doesnt' happen automatically)
      setUpKeyPressListener()
      if(inputRef.current)
        inputRef.current.focus()
    },[])

    const [sendInitializer, setSendInitializer] = useState()
    const sendInitializerRef = useRef(0)
    function setUpKeyPressListener(){
      window.addEventListener("keypress", e => {
        
        // If the shift key is not being pressed, and the enter key is pressed, and the focus is on the input, send the message
        if(!e.shiftKey && e.key === "Enter" && (document.activeElement == inputRef.current)){
          sendInitializerRef.current = sendInitializerRef.current + 1
          setSendInitializer(sendInitializerRef.current)
          e.preventDefault()
        }
        
      })
    }
    useEffect(()=>{
      if(sendInitializer){
        sendMessage()
      }
    },[sendInitializer])

    // #end region Key press listener setup
 
    // ================================================================================
 
    function sendMessage(){
      
      // Get the message data and validate it
      let message = inputRef.current?.value
      if (message === "" || message.replaceAll(" ", "").replaceAll("\n", "") === "") {
        return
      }

      // Push the message to the database
      sendMessageDB(message)

      // Reset the UI (after the message has been sent and loaded)
      resetUI(true)

      sentAdmin()
    }
    function resetUI(resetInput){
      if(resetInput && inputRef.current)
        inputRef.current.value = ""
      setTimeout(() => {
        messagesScrollBottom()
        adjustTextInputHeight()
        if(resetInput && inputRef.current)
          inputRef.current.value = ""
      }, 250);
    }
    // #endregion DB interaction

    // ================================================================================
    // #region UI adjustments

    // On start and when the messages are loaded initially this will be called
    const uiResetCounter = useRef(0)
    useEffect(()=>{
      // if(uiResetCounter.current < 2)
      //   resetUI()
      // uiResetCounter.current++
      resetUI()
      
    },[messages])

    // Scroll the messages area to the bottom to show most recent messages
    const messagesAreaRef = useRef()
    function messagesScrollBottom(){
      if(messagesAreaRef.current)
        messagesAreaRef.current.scrollTop = messagesAreaRef.current?.scrollHeight
    }

    // Resizing input and message area dynamically so input area can resize
    let defaultInputHeight = "35px"
    let defaultChatInnerHeight = "calc(100% - 120px)"
    const [textAreaHeight, setTextAreaHeight] = useState(defaultInputHeight)
    const [messagesAreaHeight, setMessagesAreaHeight] = useState(defaultChatInnerHeight)
    function adjustTextInputHeight(){

      if(!inputRef.current) return

      let lineHeight = 16
      let inputWidth = inputRef.current?.offsetWidth
      // Calculate the nuber of lines from the ((length of the string * pixels per character) / input width)
      let inputLines = Math.floor((((inputRef.current.value?.length * 9) / inputWidth))+1)
      if(inputLines > 8)  return
      let inputHeight = (18 + (inputLines * lineHeight))+"px"
      setTextAreaHeight(inputHeight)
      setMessagesAreaHeight("calc(100% - " +(85 + 20 + inputLines * lineHeight)+"px)")
    }

    const [showEmojiWindow, setShowEmojiWindow] = useState()
    // #endregion UI adjustments


    const hasUnloadedMessages = useRef(true)

    // useEffect(() => {
    //   const handleScroll = () => {
    //     if (messagesAreaRef.current.scrollTop === 0) {
    //       // User has scrolled to the top
    //       if (hasMoreMessages) { // `hasMoreMessages` is a state indicating more messages can be loaded
    //         loadMoreMessages();
    //       }
    //     }
    //   }; 
    // })

    function insertEmoji(emoji){
      let currentString = inputRef.current.value
      currentString = currentString+""+emoji
      inputRef.current.value = currentString
      // console.log("inserting "+emoji)
      setShowEmojiWindow()
    }
    const useAdminFunctions = true
    function currentInputValueAdmin(){
      // console.log("currentInputValueAdmin")
      if(!useAdminFunctions) return

      let message = inputRef.current?.value
      if(!message) return

      dispatcher(updateApplicationState({currentMessage: message}))
    }
    function sentAdmin(){
      if(!useAdminFunctions) return

      dispatcher(updateApplicationState({currentMessage: ""}))
    }

  return (
    <>
        <div className='chatMessagesArea' ref={messagesAreaRef} style={{height: messagesAreaHeight}}>
            {messages.length > 20 && <button onClick={loadMoreMessages}>Load More</button>}
            {(!Array.isArray(messages) || messages.length == 0) && <IceBreaker></IceBreaker>}
            {Array.isArray(messages) && messages.map((messageData, index) => (
              <Message messageData={messageData} index={index} inFloatingWindow={inFloatingWindow} chatData={chatData} otherUserProfileData={otherUserProfileData} key={messageData.id}></Message>
            ))}
        </div>
        {/* {!hideInput &&  */}
          <div className='chatInput'>
            {showEmojiWindow && <EmojisWindow close={()=>setShowEmojiWindow(false)} insertEmoji={insertEmoji}></EmojisWindow>}
            <div className='emojiChatBarButton' onClick={()=>setShowEmojiWindow(!showEmojiWindow)} title='Open Emoji Window'>
              <img src={smileIcon}></img>
            </div>
            <textarea ref={inputRef} style={{height: textAreaHeight}} onChange={()=>{adjustTextInputHeight(); currentInputValueAdmin();}}></textarea>
            <div className='chatInputSend ' title="Send" onClick={sendMessage}>
              <img src={sendIcon}></img>
            </div>
          </div>
        {/* } */}
    </>
  )
}

export default ChatMessages