import React, { useEffect, useState } from 'react'
import editIcon from "../../Media/editIconBlue.png"
import messageIcon from "../../Media/chatIconBlue.png"
import { addToActivityLog, objectKeyToArray, objectLength, objectToArray, randomBannerImage, readableJson, userIDToName } from '../../Functions'
import { useDispatch, useSelector } from 'react-redux'
import ProfileViewImages from './ProfileViewImages'
import ProfileEditInputField from './ProfileEditInputField'
import ImageUploader from './ImageUploader'
import { db, openImageArrayViewer, setChatUserID, setShowChat } from '../../GlobalState/AppSlice'
import DScreening from './DScreening'
import { onValue, ref } from '@firebase/database'
import { isMobile } from 'react-device-detect'
import ProfileImages2 from './ProfileImages2'
import Navbar2 from '../NavBar/Navbar2'
// import ProfileViewImages from './ProfileViewImages'

function Profile2({profileID}) {
    
  // Global state interaction
  const userID = useSelector(state => state.appSlice.userID)
  const thisUsersProfileData = useSelector(state => state.appSlice.profileData)
  const dispatcher = useDispatch()
  
  // The profile data that is displayed
  const [profileData, setProfileData] = useState({})

  // The tab that is being viewed
  const [viewSection, setViewSection] = useState("About")
  // If the user is editing their profile
  const [editMode, setEditMode] = useState(false)
  // A random banner for the top (shows if they have not set one)
  const [bannerImageURL, setBannerImageURL] = useState(randomBannerImage())

  // The fields that display on the user profile
  const textDisplayFields = [
    // {
    //   title: "Name",
    //   key: "name",
    // },
    {
      title: "Age",
      key: "age",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Zip Code",
      key: "zipCode",
    },
    {
      title: "Height (in inches)",
      key: "height",
    },
    {
      title: "Last Active",
      key: "lastActive",
    },
    {
      title: "Eye Color",
      key: "eyeColor",
    },
    {
      title: "Hair Color",
      key: "hairColor",
    },
    {
      title: "User Type",
      key: "userType",
      requiresAdmin: true,
    },
    {
      title: "Note",
      key: "note",
      requiresAdmin: true,
    },
  ]

  // Set the profile data that is displayed based on the dependencies 
  useEffect(()=>{
    getProfileData()
  },[userID, profileID, thisUsersProfileData])

  // Decides which profile data to show and and fetches it if necessary 
  function getProfileData(){
    // If they are viewing themselves
    if(!profileID || profileID === userID){

      setProfileData(thisUsersProfileData)
    }
    // If they are viewing another user 
    else{
      onValue(ref(db, "freeFer/users/userProfiles/"+profileID), snap => {
        setProfileData(snap.val())
      })
    }
  }


  // Account functionality
  function signOutFunction(){

  }
  function deleteUser(){
    dispatcher(deleteUser(userID))
  }

  // If its in edit mode display the edit input, else if there is user profile data for that key display the row, else omit it 
  function textDisplayField(textDisplayObject){
    if(editMode)
      return (
        <>
          {textDisplayObject.requiresAdmin ?
            <>
              {((userID === "v9eucYVZG6MtMdx6JqD0SXtDmD53") || (thisUsersProfileData?.userType === "donor")) &&
                <div className='textDisplayRow' key={textDisplayObject?.key+"edit"}>
                  <div className='textDisplayRowElement'>{textDisplayObject?.title}: </div>
                  <div className='textDisplayRowElement'>
                    {/* {textDisplayModifier(textDisplayObject?.key, profileData && profileData[textDisplayObject?.key])} */}
                    {profileData && profileData !== null && typeof profileData === "object" && profileData?.[textDisplayObject?.key]}
                  </div>
                </div>
              }
            </>
            :
            <div className='textDisplayRow' key={textDisplayObject?.key+"edit"}>
              <div className='textDisplayRowElement'>{textDisplayObject?.title}: </div>
              <div className='textDisplayRowElement'>
                {/* It would be better if this recieved the profileData and profileID and key, could display and update without refetching or searching */}
                <ProfileEditInputField attributeKey={textDisplayObject?.key} attributeName={textDisplayObject?.title} userIDOverride={profileID} profileData={profileData}></ProfileEditInputField>
              </div>
            </div>
          }
        </>
      )
    else if((typeof profileData === "object") && profileData?.[textDisplayObject?.key])
      return (
        <>
        {textDisplayObject.requiresAdmin ?
          <>
            {((userID === "v9eucYVZG6MtMdx6JqD0SXtDmD53") || (thisUsersProfileData?.userType === "donor")) &&
              <div className='textDisplayRow' key={textDisplayObject?.key}>
                {/* <div>{"userID "+userID}</div>
                <div>{"profileData?.userType "+profileData?.userType}</div> */}
                <div className='textDisplayRowElement'>{textDisplayObject?.title}: </div>
                <div className='textDisplayRowElement'>
                  {/* {textDisplayModifier(textDisplayObject?.key, profileData && profileData[textDisplayObject?.key])} */}
                  {profileData && profileData !== null && typeof profileData === "object" && profileData?.[textDisplayObject?.key]}
                </div>
              </div>
            }
          </>
          :
          // <></>
          <div className='textDisplayRow' key={textDisplayObject?.key}>
            <div className='textDisplayRowElement'>{textDisplayObject?.title}: </div>
            <div className='textDisplayRowElement'>
              {/* {textDisplayModifier(textDisplayObject?.key, profileData && profileData[textDisplayObject?.key])} */}
              {profileData && profileData !== null && typeof profileData === "object" && profileData?.[textDisplayObject?.key]}
            </div>
          </div>
        }
        </>
      )
    else
        return (<></>)

  }

  function profileSectionDisplay(){
    if(viewSection === "About" ){
      return (
        
            <>
              <div className={'profileTileColumn '+(isMobile ? "":" profileTileColumnDesktopLeft")}>

                <div className='profileTile'>
                  <div className='profileTileTitle'>
                      Pictures
                  </div>
                  {editMode ? 
                    <ProfileImages2 userIDOverride={profileID}></ProfileImages2>
                    :
                    <ProfileViewImages ProfileViewImages profileData={profileData}></ProfileViewImages>
                  }
                </div>
                
              </div>
              <div className={'profileTileColumn '+(isMobile ? "":" profileTileColumnDesktopRight")}>

                <div className='profileTile'>
                  <div className='profileTileTitle'>
                    Bio
                    {/* <div>{profileID}</div> */}
                  </div>
                  <div className='profileTileInner'>
                    {editMode?
                    <div className='editProfilePageBio'>
                      <ProfileEditInputField attributeKey={"bio"} attributeName={"Bio"} userIDOverride={profileID} textarea profileData={profileData}></ProfileEditInputField>
                    </div>
                    :
                    <pre>
                      {profileData?.bio}
                    </pre>
                    }
                  </div>
                </div>

                <div className='profileTile'>
                  <div className='profileTileTitle'>
                    Extended Info
                  </div>
                  <div className='profileTileInner'>
                    {textDisplayFields.map(textDisplayObject => (
                      <div key={textDisplayObject?.key+(editMode?"edit":"")}>
                        {textDisplayField(textDisplayObject)}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {/* {"isAnon: "+isAnon+" profileID: "+profileID+" userID: "+userID} */}
                </div>
                {/* {isAnon && (!profileID || (profileID === userID)) &&
                    <div className='profileTile'>
                      <div className='flexBox'>
                          <CreateAccountInputs></CreateAccountInputs>
                      </div>
                    </div>
                } */}


              </div>
            </>
      )
    }
    if(viewSection === "Pictures" ){
      return(
        <ProfileViewImages profileData={profileData}></ProfileViewImages>
      )
    }
    if(viewSection === "AccountOptions" ){
      return(
        <div  className={'profileTileColumn '+(isMobile ? "":" profileTileColumnDesktop")}>
          <div className='profileTile'>
            <button className='accountOptionsButton'>Change Password</button>
            <button className='accountOptionsButton'>Change Email</button>
            <button onClick={signOutFunction} className='accountOptionsButton'>Sign Out</button>
            <button onClick={deleteUser} className='accountOptionsButton'>Delete Account</button>
          </div>
        </div>
      )
    }
    if(viewSection === "Screening" ){
      return(
        <>
          <DScreening></DScreening>
        </>
      )
    }
  }

  // ========================================
  // #region Activity Log: View profile

  useEffect(()=>{
    if(profileID === userID)
      addToActivityLog("View Profile (their own)")
    else
      addToActivityLog("View Profile: "+profileID, userID)
  },[profileID])

  useEffect(()=>{
    return ()=>{
      addToActivityLog("Closed Profile: "+profileID, userID)
    }
  },[])

  // #endregion Activity Log

  return (
    <div className='profile'>
        {/* {showProfileImageWindow && <ImageViewer startingIndex={0} imageUrlArray={[profileData?.images?.main?.downloadURL]} close={()=>setShowProfileImageWindow(false)}></ImageViewer>} */}
        <div className='pageTop'>
            {/* Banner */}
            <div className='profileBanner'>
                {editMode?
                    // <div></div>
                    <ImageUploader
                        imageIdentifier={"banner"}
                        dataPath={"freeFer/users/userProfiles/" + profileID + "/images/"+"banner"}
                        imagePath={"freefer/users/" + profileID + "/"}
                        imagePathObject={profileData?.images?.banner}
                    ></ImageUploader> 
                    :
                    <img src={profileData?.images?.banner?.downloadURL || bannerImageURL}></img>
                }
            </div>
            <div className='profileTopBar'>
                <div className='profileMainImage' onClick={()=>dispatcher(openImageArrayViewer({imageArray: objectKeyToArray(profileData?.images, "downloadURL"), index: (objectLength(profileData?.images) - 1)}))}>
                  <img src={profileData?.images?.main?.downloadURL}></img>
                </div>
                <div className='profileName'>
                    {editMode?
                      <ProfileEditInputField attributeKey={"name"} attributeName={"Name"} userIDOverride={profileID} profileData={profileData}></ProfileEditInputField>
                      :
                      <>
                        {profileData?.name || "Anonymous User"}
                      </>
                    }
                </div>
                {(userID === profileID || userID === "v9euCYVZG6MtMdx6JqD0SXtDmD53") && 
                    <>
                        <div className='imageButtonPosition imageButtonSize editProfileButton' onClick={()=>setEditMode(!editMode)}>
                            <div className='imageButtonImage2'>
                                <img src={editIcon}></img>
                            </div>
                            <div className='imageButtonLabel'>{editMode ? "Stop Editing" : "Edit Profile"}</div>
                        </div>
                    </>
                }
                <div className='profileButtons'>
                    <div 
                        className={'profileButton '+(viewSection === "About" ? "profileButtonSelected":"")} 
                        onClick={()=>setViewSection("About")}
                    >
                        About
                    </div>

                    {/* {profileData?.userType === "donor" &&
                        <div 
                            className={'profileButton '+(viewSection === "Screening" ? "profileButtonSelected":"")} 
                            onClick={()=>setViewSection("Screening")}
                        >
                            Screening
                        </div>
                    } */}

                    {profileID !== userID &&
                        <div 
                            className={'profileButton '+(viewSection === "Message" ? "profileButtonSelected":"")} 
                            // onClick={()=>setViewSection("Message")}
                            onClick={()=>{dispatcher(setChatUserID(profileID)); dispatcher(setShowChat(true))}}
                        >
                            <div className='profileButtonText'>
                                Message
                            </div>
                            <div className='smallIcon'>
                                <img src={messageIcon}></img>
                            </div>
                        </div>
                    }
                    {/* {profileID === userID &&
                        <div 
                            className={'profileButton '+(viewSection === "AccountOptions" ? "profileButtonSelected":"")} 
                            onClick={()=>setViewSection("AccountOptions")}
                        >
                            Account Options
                        </div>
                    } */}
                    {/* {userID === "v9euCYVZG6MtMdx6JqD0SXtDmD53" &&
                        <div 
                            className={'profileButton '+(viewSection === "Admin" ? "profileButtonSelected":"")} 
                            onClick={()=>setViewSection("Admin")}
                        >
                            Admin
                        </div>
                    } */}
                </div>
            </div>
        </div>
        <div className='profileBottom'>
            <div className='profileBottomInner'>
                {profileSectionDisplay()}
            </div>
        </div>
    </div>
  )
}

export default Profile2