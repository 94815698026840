import React from 'react'
import Navbar from '../NavBar/Navbar'
import "./Pages.css"
import Navbar2 from '../NavBar/Navbar2'

function PageContiner({children}) {
  return (
    <div className='pageOuter'>
        <Navbar2></Navbar2>
        <div className='appScroller'>
            {children}
        </div>
    </div>
  )
}

export default PageContiner