import React, { useState } from 'react'
import "./Windows.css"
import { useDispatch, useSelector } from 'react-redux'
import { closeImageArrayViewer, setImageArrayViewerIndex } from '../../GlobalState/AppSlice'

function ImageViewer({userApplicationState}) {

    const imageArrayViewerArrayGlobal = useSelector(state => state.appSlice.imageArrayViewerArray)

    let imageArrayViewerArray
    if (userApplicationState?.imageArrayViewerArray !== undefined)
        imageArrayViewerArray = userApplicationState?.imageArrayViewerArray
    else
        imageArrayViewerArray = imageArrayViewerArrayGlobal

    const imageArrayViewerIndexGlobal = useSelector(state => state.appSlice.imageArrayViewerIndex)

    let imageArrayViewerIndex
    if (userApplicationState?.imageArrayViewerIndex !== undefined)
        imageArrayViewerIndex = userApplicationState?.imageArrayViewerIndex
    else
        imageArrayViewerIndex = imageArrayViewerIndexGlobal

    const dispatcher = useDispatch()

    // const [imageIndex, setImageIndex] = useState(imageArrayViewerIndex || 0)

    function indexUp(){
        if(!imageArrayViewerArray || !Array.isArray(imageArrayViewerArray)) return
        if(((imageArrayViewerIndex + 1) < imageArrayViewerArray?.length) )
            dispatcher(setImageArrayViewerIndex(imageArrayViewerIndex +1))
        else
            dispatcher(setImageArrayViewerIndex(0))
    }
    function indexDown(){
        if(!imageArrayViewerArray || !Array.isArray(imageArrayViewerArray)) return
        if(((imageArrayViewerArray - 1) > 0) )
            dispatcher(setImageArrayViewerIndex(imageArrayViewerArray - 1))
        else
            dispatcher(setImageArrayViewerIndex(imageArrayViewerArray?.length - 1))
    }

  return (
    <>
        {imageArrayViewerArray &&
            <div className='imageViewerWindow'>
                <div className='closeButton' onClick={()=>dispatcher(closeImageArrayViewer())}>x</div>
                <div className='imageViewerButton imageViewerButtonRight' onClick={indexUp}>{">"}</div>
                <div className='imageViewerButton imageViewerButtonLeft' onClick={indexDown}>{"<"}</div>
                {imageArrayViewerArray && <img src={imageArrayViewerArray[imageArrayViewerIndex]}></img>}
                <div className='imageViewerMessage'>{"Image " + (imageArrayViewerIndex + 1) + " of " + imageArrayViewerArray?.length}</div>
            </div>
        }
    </>
  )
}

export default ImageViewer