import React from 'react'
import bannerVideo from "../../Media/bannerVideo.mp4"
import "./Home.css"
import CircleInfoBox from './CircleInfoBox'
import twoLadies from "../../Media/2Ladies.jpg"
import eAndS from "../../Media/EandS.png"
import petriDish from "../../Media/petriDish.jpg"
import ladyWithPicture from "../../Media/ladyWithPicture.jpg"
import DirectoryCard from './DirectoryCard'
import { Link } from 'react-router-dom'
import { setShowChat, syncAppState } from '../../GlobalState/AppSlice'
import { useDispatch, useSelector } from 'react-redux'
import Navbar2 from '../NavBar/Navbar2'
import { isMobile } from 'react-device-detect'
import { addToActivityLog } from '../../Functions'

function Home({userApplicationState}) {
    
    const userID = useSelector(state => state.appSlice.userID)
    const dispatcher = useDispatch()

    function scrollToFoundation(){
        addToActivityLog("Clicked Learn More Button", userID)

        let foundatoinElement = document.getElementById("foundationStatement")
        if(foundatoinElement)
            foundatoinElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

  return (
    <>
        <Navbar2 top color={"#D65E4D"}></Navbar2>
        <div className='appScroller' id={"appScroller"+(userApplicationState?.userID || userID)}>
            {/* Banner */}
            <div className='banner'>
                {/* {!isMobile && <video src={bannerVideo} className='bannerVideo' autoPlay loop muted></video>} */}
                <video src={bannerVideo} className='bannerVideo' autoPlay loop muted></video>
                {/*  */}
                <div className='bannerText'>
                    <div className={'bannerText1 '+(isMobile ? "":" bannerText1Desktop")}>
                        {"BEGIN BUILDING "}
                    </div>
                    <div className={'bannerText1 '+(isMobile ? "":" bannerText1Desktop")}>
                        YOUR FAMILY
                    </div>
                    <div>

                    <div className={'bannerText2 '+(isMobile?"":"")}>
                        <div className={(isMobile?"":"bannerText2Desktop")}>
                        Expert screening and connections. 
                        </div>
                        <div className={(isMobile?"":"bannerText2Desktop")}>
                        Completley <span className='goldLetters'>Free</span>. 
                        </div>
                    </div>
                    </div>
                    <div>
                        <Link to={"/search"}>  
                            <div className='bannerButton'>
                                Search Sperm Donors
                            </div>
                        </Link>
                        <div className='bannerButton' onClick={scrollToFoundation}>
                            Learn More
                        </div>
                    </div>
                </div>
                <div className='scrollToBox' id="foundationStatement"></div>
            </div>

            {/* Info Section 1 */}

            <div className={'butterflySection orangeButterflySection '+(isMobile ? "":" butterflySectionDesktop")} >
                <div className={'infoTitle '+(isMobile ? "":"infoTitleDesktop")}>
                    Completely Free
                </div>
                <div className='infoText'>
                    Funded by donations and charitable organizations all of our services are provided completely free of charge. 
                    These services are available to everyone regardless of economic status or orientation.
                    If you benifit from our services please consider donating.  
                    Scroll down to see the free list of of donors.
                </div>
            </div>   

            {/* Info Section 2 */}

            <div className={'noBackgroundSection circleInfoArea ' + (!isMobile ? "circleInfoAreaDesktop":"")}>
                <CircleInfoBox
                    title="LGBTQ Family Building"
                    content ="One of our founding beliefs is that everyone should be able to experience the joy and purpose of parenthood regardless of their orientation. We aim to make this more accessable to everyone. Due to the nature of our service many of our users are members of the LGBT community so if you are member of this community you can rest assured you are very welcome."
                    image={twoLadies}
                    isMobileOverride={userApplicationState?.isMobile}
                ></CircleInfoBox>
                <CircleInfoBox
                    title="Donor Connections"
                    content="The easiest and most cost effective way to enable a family to concieve is by connecting them with a donor. We maintain a free list of screened and verified donors that can be viewed on this site. Scroll down for the link to the list."
                    image={eAndS}
                    right
                    isMobileOverride={userApplicationState?.isMobile}
                ></CircleInfoBox>
                <CircleInfoBox
                    title="Expert Screening"
                    content="All of the donor profiles on our site have been screened by experts to ensure safety and quality."
                    image={petriDish}
                    isMobileOverride={userApplicationState?.isMobile}
                ></CircleInfoBox>
            </div>

            {/* Quote over an image */}

            <div className={'quoteBox '+(isMobile ? "":"quoteBoxDesktop")}>
                <img src={ladyWithPicture}></img>
                <div className={'quoteOverlay '+(isMobile? "":"quoteOverlayDesktop")}></div>
                <div className='quote'>
                    {"The joy and purpose of parenthood is within reach."}
                </div>
            </div>

            {/* Directory */}

            <div className='butterflySection purpleButterflySection'> 
                <div className='butterflySectionTitleArea'>
                    <div className='directoryTitle'>
                        <div className={isMobile ? "":'directoryTitleSectionDesktop'}>{"Find what you're"}&nbsp;</div>
                        <div className={isMobile? "":'directoryTitleSectionDesktop'}>{" looking for here"}</div>
                        
                    </div>
                    <div className={'directoryDescription '+(isMobile? "":"directoryDescriptionDesktop")}>
                        The links below provide access to the lists that enable intended parents to start their journey. 
                        Feel free to browse the lists, no account is required to view or message the listed donors. 
                        If you are interested in giving the gift of family please apply in the application portal below.
                    </div>
                </div>           
                <div>
                    <DirectoryCard
                        title={"Find a sperm donor"}
                        content={`
                            All of the profiles on the list have been thorougily screened and validated. 
                            To protect the privacy of the donors only first names will be shown.  
                            Most donors do not charge any fee but if a fee is discussed it is soely between the donor and the intended family.
                        `}
                        linkTo={"/search"}
                        linkText={"View Sperm Donor List"}
                        userApplicationState={userApplicationState}
                    ></DirectoryCard>
                    {/* <DirectoryCard
                        title={"Find a surrogate"}
                        content={`
                            All of the profiles on the list have been thorougily screened and validated. 
                            To protect the privacy of the donors only first names will be shown.
                            Any fee discussed through the surrogacy process is solely between the surrogate and the intended family.
                        `}
                        linkTo={"/search"}
                        linkText={"View Surrogate List"}
                        userApplicationState={userApplicationState}
                    ></DirectoryCard> */}
                    <DirectoryCard
                        title={"Apply to become a donor"}
                        content={`
                            Giving the gift of family can be easy and rewarding. 
                            Only a small percentage of applicants are able to be listed, click the button below to see if you qualify.
                        `}
                        linkTo={"/apply"}
                        linkText={"Apply"}
                        userApplicationState={userApplicationState}
                    ></DirectoryCard>

                </div>
                <div>
                    <DirectoryCard
                        title={"Donate to the fund"}
                        content={`
                            The Sarah Johnson Fund for Human Development is maintained through the genorosity of individuals who care, if you would like to donate to show your appreciation please click the button below.
                        `}
                        linkTo={"/donate"}
                        linkText={"Donate"}
                        userApplicationState={userApplicationState}
                    ></DirectoryCard>
                    <DirectoryCard
                        title={"Contact Us"}
                        content={(
                            <div>
                                {"Feel free to ask any questions you might have or give us feedback on the site. "}
                                {"Contact us with the form at the link below or by email at: "} 
                                <div>
                                    <a href='mailto:Contact@FreedomFertility.com' className='contactEmailLink'>
                                        Contact@FreedomFertility.com
                                    </a>
                                </div>
                            </div>
                        )}
                        linkTo={"/contact"}
                        linkText={"Contact Us Page"}
                        userApplicationState={userApplicationState}
                    ></DirectoryCard>

                </div>
            </div> 

            {/* Bottom Bar */}

            <div className='bottomBar'>
                <div className='bottomBarColumn'>
                    <Link to="/search" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Search</div>
                    </Link>
                    <div className='bottomLink' onClick={()=>dispatcher(setShowChat(true))}>Chat</div>
                    <Link to={'/profile/'+userID}  onClick={()=>dispatcher(syncAppState())}>
                        <div className="bottomLink">Account</div>
                    </Link>
                    <Link to="/" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Home</div>
                    </Link>
                </div>
                <div className='bottomBarColumn'>
                    <Link to="/donate" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Donate</div>
                    </Link>
                    <Link to="/careers" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Careers</div>
                    </Link>
                    <Link to="/contact" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Contact</div>
                    </Link>
                    {/* <Link to="/apply" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Apply</div>
                    </Link> */}
                    <Link to="/admin" onClick={()=>dispatcher(syncAppState())}>
                        <div className='bottomLink'>Admin</div>
                    </Link>
                </div>
            </div>

        </div>

    </>
  )
}

export default Home